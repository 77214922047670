import React, { useState } from "react";
import classes from "./filterButton.module.css";

const FliterButton = (props) => {

  function handleClick() {
    props.onSelect(props.title)
  }

  return (
    <div className={`${classes.container} ${props.selected && classes.active}`} onClick={() => handleClick()}>
      <img className={classes.icon}
        src={props.icon}
        alt="" />
      <h1 className={classes.title}>{props.title}</h1>
    </div>
  );
};

export default FliterButton;