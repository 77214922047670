import volunteeringImg1 from "../imgs/icons/programs/volunteer.png";
import tourismImg1 from "../imgs/icons/programs/tourism.png";
import africanImg1 from "../imgs/icons/programs/african.png";
import juniorsImg1 from "../imgs/icons/programs/juniors.png";
import erasmusImg1 from "../imgs/icons/programs/erasmus.png";




const programs =
  [
    { title: "Volunteering", icon: volunteeringImg1, },
    { title: "Adventurous & Road trips", icon: tourismImg1, },
    { title: "Have a Dream Juniors", icon: juniorsImg1, },
    { title: "EU program", icon: erasmusImg1, },
    // { title: "Special Requests",  icon: exchangeImg1, }
  ]

export default programs;