import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, { useState } from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import EventCard from "../event card/EventCard";
import classes from "./eventList.module.css";

import tripsTypes from "../../assets/data/tripsTypes";
import notFound from "../../assets/imgs/notFound/programs.png";

const EventList = (props) => {
  // const [Eventcards, SetEventcards] = useState([0, 0, 0, 0]);
  const [loading, setLoading] = useState(true);

  const Eventcards = props.events;

  return (
    <div>
      <div className={classes.secheader}>
        {props.title && <h3>Trips</h3>}

      </div>
      <div className={classes.list}>
        {Eventcards?.length === 0 ? (
          <div className={classes.noevents}>
            <img alt="notFound" src={notFound} />
            <p>No programs in this category</p>
          </div>
        ) : (
          <AliceCarousel
            className={classes.carousel}
            mouseTracking items={Eventcards?.map((card) => (
              <EventCard
                id={card._id}
                key={card._id}
                img={card.image}
                title={card.name}
                date={card.startDate}
                duration={card.duration}
                location={card.location}
                price={card.price} //Price attribute is not provided by backend response
                load={props.load}
                type={tripsTypes[card.type]?.name}
                review={props.review}
                wishlist={props.wishlist}
              />
            ))}
            responsive={{
              0: {
                items: 1,
                itemsFit: 'fill'
              },
              1100: {
                items: 2,
                itemsFit: 'fill'
              },
              1024: {
                items: 3,
                itemsFit: 'fill',
              }
            }}
            renderDotsItem={(e) => { return <div className={e.isActive ? classes.carouselIndexBtnActive : classes.carouselIndexBtn}></div> }}
            disableButtonsControls={true}
          />
        )}
      </div>
      {!loading && Eventcards.length !== 0 && (
        <div className={classes.moreBtn}>
          <button type="button">See more</button>
        </div>
      )}
    </div>
  );
};

export default EventList;
