import React from "react";
import classes from "./partners.module.css";
import img1 from "../../../assets/imgs/partners/ministry.jpg";
import img2 from "../../../assets/imgs/partners/erasmus.jpg";
import img3 from "../../../assets/imgs/partners/european.jpeg";
import img4 from "../../../assets/imgs/partners/paymob.png";
import img5 from "../../../assets/imgs/partners/Valu.png";
import img6 from "../../../assets/imgs/partners/PDS.png";
import img7 from "../../../assets/imgs/partners/zbooni.jpg";

const Partners = () => {
  return (
    <div className={classes.container}>
      <h1 className={classes.header}>Our Partners</h1>
      <div className={classes.partners}>
        <img className={classes.img1} src={img1} alt="partner" />
        <img className={classes.img3} src={img3} alt="partner" />
        <img className={classes.img7} src={img7} alt="partner" />
        <img className={classes.img4} src={img4} alt="partner" />
        <img className={classes.img5} src={img5} alt="partner" />
        <img className={classes.img2} src={img2} alt="partner" />
        <img className={classes.img5} src={img5} alt="partner" />
        <img className={classes.img6} src={img6} alt="partner" />
        <img className={classes.img1} src={img1} alt="partner" />
      </div>
    </div>
  );
};

export default Partners;
