import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import classes from "./admin.module.css";
import AdminPrograms from "./programs/AdminPrograms";
import AdminDashboard from "./dashboard/AdminDashboard";
import SideBar from "./../../layouts/sideBar/Sidebar";
import AdminUsers from "./users/AdminUsers";
import ProgramDetails from "./programs/programDetails/ProgramDetails";
import AdminCounter from "./counterSection/AdminCounter";

const AdminPage = () => {
  return (
    <>
      <div className={classes.container}>
        <SideBar />
        <div className={classes.content}>
          <Routes>
            <Route path="/" element={<AdminDashboard />} />
            <Route path="/programs">
                <Route index element={<AdminPrograms />} />
                <Route path=":id/*" element={<ProgramDetails />} />
            </Route>
            <Route path="/users" element={<AdminUsers />} />
            <Route path="/counter" element={<AdminCounter />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default AdminPage;
