import React, { useState, useEffect } from "react";
import classes from "./home.module.css";
import CarouselHeroSection from "./CarouselHeroSection/CarouselHeroSection";
import CounterSection from "./counter/CounterSection";
import Values from "./newValues/Values";
import Anchor from "./anchor/anchor";
import axios from "../../requests/axios";
import routes from "../../requests/routes";
import Subscribe from "./subscribe/Subscribe";
import Partners from "./partners/Partners";

import home from "../../assets/imgs/home.webp";
import home2 from "../../assets/imgs/home2.webp";
import home3 from "../../assets/imgs/home3.webp";
import ProgramList from "../../generic components/Program List/ProgramList";
import ReviewsList from "../../generic components/Reviews List/ReviewsList";
import SpecialRequest from "./specialRequest/SpecialRequest";

import WhatsappIcon from "./whatsappIcon/whatsappIcon";

const HomePage = () => {
  const [homeData, setHomeData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getHome() {
    try {
      const response = await axios.get(routes.getHome);
      setHomeData(response.data);
      setLoading(false);
    } catch (err) { }
  }
  useEffect(() => {
    getHome();
  }, []);

  return (
    <>
      <div className={classes.container}>
        <CarouselHeroSection
          title="FLY TO CONNECT"
          btn="Book Now"
          images={[home2, home3, home]}
          tintColor="var(--green)"
        />

        <ProgramList
          title={true}
          events={homeData.programs ? homeData.programs.slice(0, 3) : [0, 0, 0]}
          load={loading}
        />
        <CounterSection counts={homeData.counts ? homeData.counts : null} load={loading} />
        <Values />
        <ReviewsList
          title={true}
          events={homeData.reviews ? homeData.reviews : [0, 0, 0]}
          load={loading}
        />
        <Partners />
        <div className={classes.subscribeSection}>
          <Subscribe />
        </div>
        <Anchor />
        <WhatsappIcon />
        <SpecialRequest />
      </div>
    </>
  );
};

export default HomePage;
