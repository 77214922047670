import classes from "./eventCard.module.css";
import React, { useEffect, useState } from "react";
import PinDropIcon from "@mui/icons-material/PinDrop";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { Link, useNavigate } from "react-router-dom";
import { Skeleton } from "antd";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RateReviewIcon from "@mui/icons-material/RateReview";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import IconButton from "@mui/material/IconButton";
import ReviewPopUp from "../reviewPopUp/ReviewPopUp";
import axios from "../../requests/axios";
import routes from "../../requests/routes";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/userSlice";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const EventCard = (props) => {
  const [wishList, setWishlist] = useState(props.wishlist);
  const [newCard, setNewCard] = useState(props.location === "Zanzibar");
  const [review, setReview] = useState(props.review);
  const [open, setOpen] = React.useState(false);
  const [currency, setCurrency] = useState("$");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();


  function parseDate(date) {
    if (!date) return "";
    return new Date(date).toLocaleString().split(",")[0].replaceAll("/", "-");
  }

  async function addToWishlistFun() {
    try {
      await axios.patch(routes.addToWishlist, { progId: props.id });
      dispatch(userActions.addToWishlist({ id: props.id }));
      toast.success("Added to wishlist");
    } catch (err) { }
  }
  async function removeFromWishlistFun() {
    try {
      await axios.delete(routes.removeFromWishlist, {
        data: { progId: props.id },
      });
      dispatch(userActions.removeFromWishlist({ id: props.id }));
      toast.success("Removed from wishlist");
    } catch (err) { }
  }

  const addToWishlist = (event) => {
    event.preventDefault();
    if (!user.loggedIn) {
      toast((t) => <div style={{ display: "flex", gap: "2rem" }}>
        Please login to add to wishlist
        <button
          style={{ backgroundColor: "#F46444", color: "white", padding: "0.5rem 1rem", border: "none", borderRadius: "5px" }}
          onClick={() => {
            toast.dismiss(t.id)
            navigate("/login", { state: { from: window.location.pathname } });
          }}>
          Login
        </button>
      </div>
      );
      return;
    }

    if (!wishList) {
      addToWishlistFun();
    } else {
      removeFromWishlistFun();
    }
    setWishlist(!wishList);
  };

  useEffect(() => {
    if (user?.wishlist?.includes(props.id)) {
      setWishlist(true);
    }
    if (props.currency === "USD") {
      setCurrency("$");
    }
    else if (props.currency === "EUR") {
      setCurrency("€");
    }
    else if (props.currency === "EGP") {
      setCurrency("EGP");
    }
  }, []);

  return (
    <div className={newCard ? classes.newCard : classes.card}>
      {props.load ? (
        <div className={classes.imgSkeleton}>
          <Skeleton.Avatar shape={"square"} size={100} active />
        </div>
      ) : (
        <Link to={`/programs/${props.id}`}>
          <div className={classes.cardImage}>
            {review && (
              <div className={classes.review}>
                <div className={classes.reviewIcon}>
                  <IconButton
                    size="large"
                    onClick={() => setOpen(true)}
                    className={classes.reviewIcon}
                    aria-label="review"
                    sx={{
                      fontSize: "5rem",
                      color: "#2c4c4c",
                      backgroundColor: "white",
                      background: "white",
                      "&:hover": { backgroundColor: "white" },
                    }}
                  >
                    <RateReviewIcon />
                  </IconButton>
                </div>
                <ReviewPopUp
                  open={open}
                  setOpen={setOpen}
                  img={props.img}
                  title={props.title}
                  location={props.location}
                  id={props.id}
                />
              </div>
            )}
            <img src={props.img} alt="event_img" />
          </div>
        </Link>
      )}
      {props.load ? (
        <div className={classes.txtSkeleton}>
          <Skeleton active />
        </div>
      ) : (
        <Link to={`/programs/${props.id}`}>
          <div className={classes.cardContent}>
            <div className={classes.wishlist} onClick={addToWishlist}>
              {wishList ? (
                <FavoriteIcon sx={{ fontSize: "2rem", color: "#F46444" }} />
              ) : (
                <FavoriteBorderIcon
                  sx={{ fontSize: "2rem", color: "#2c4c4c" }}
                />
              )}
            </div>
            {newCard ? (
              <div className={classes.new}>
                <FiberNewIcon sx={{ fontSize: "5rem", color: "#F46444" }} />
              </div>
            ) : null}
            <h3 data-testid="title">{props.title}</h3>
            <div className={classes.location}>
              <PinDropIcon /> {props.location}
            </div>
            <div className={classes.time}>
              <DateRangeIcon />
              {props.isRecurrent ? "All year round" :parseDate(props.date)}
            </div>

            {/* <div className={classes.location}><b>{props.type}</b></div> */}

            <div className={classes.price}>
              <p>{props.isProfile ? "Traveled At" : "Starting Price"}</p>{" "}
              <p>
                {props.isProfile ? parseDate(props.travelledAt) : currency + props.price}
              </p>
            </div>

            <button className={classes.btn}>
              {props.isProfile ? "Review Program" : "Read More"}{" "}
              <TrendingFlatIcon />{" "}
            </button>
          </div>
        </Link>
      )}
    </div>
  );
};

export default EventCard;
