import classes from "./statCard.module.css";

function StatCard(props) {
  const getAverage = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;
  const getPercentage = (arr) =>
    (((arr[arr.length - 1] - getAverage(arr)) * 100) / getAverage(arr)).toFixed(
      1
    );

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <div className={classes.left}>
          <div className={classes.icon}>{props.icon}</div>
          <div className={classes.title}>{props.title}</div>
        </div>
        <div className={classes.right}>{props.number}</div>
      </div>
    </div>
  );
}

export default StatCard;
