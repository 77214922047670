import classes from "./aboutUs.module.css";

const AboutUs = () => {
  return (
    <section className={classes.container}>
      <div className={classes.intro}>
        <h1>About Us</h1>
        <h4>
          Have A Dream provides a unique travel experience through volunteering,
          culture exchange, adventure, and road trips.
        </h4>
      </div>
      <div className={classes.body}>
        <p>
          In 2013, when the term 'youth exchange' was unfamiliar to many in
          Egypt, a man with dreams traveled to China to experience intercultural
          exchange and spread awareness of such opportunities. Mahmoud
          Abdelmageed, inspired by his work with UNESCO, returned home
          determined to open new doors for Egyptian youth. In 2014, Have A Dream
          was born, with the mission to provide life-changing opportunities for
          young Egyptians to explore different cultures, exchange ideas, and
          make a global impact through volunteering.
        </p>
        <p>
          Since then, we have developed projects with the goal of enriching
          youth experiences. We’ve expanded into diverse fields such as
          teaching, farming, community service, and construction. In response to
          the Beirut explosion, we provided crucial support, demonstrating our
          commitment to helping communities in need.
        </p>
        <p>
          Our journey has taken us across Europe, Asia, and Africa, and we are
          proud to be accredited by Erasmus+ and the European Solidarity Corps.
          Through these programs, we’ve launched projects that tackle youth
          development, entrepreneurship, the arts, environmental issues, and
          cultural heritage.
        </p>
        <p>
          With a community of more than 1300 adventurers who have traveled to 50
          countries across 3 continents, we remain committed to empowering youth
          through volunteering and intercultural exchange.
        </p>
        <p>
          Join us in creating a more connected, compassionate world and making a
          lasting difference through youth empowerment and intercultural
          exchange
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
