import classes from './valuesCard.module.css';

function ValuesCard(props) {
  const { title, description, icon } = props;

  return (
    <div className={classes.card}>
      <div className={classes.icon}>{icon}</div>
      <h2 >{title}</h2>
      <p >{description}</p>
    </div>
  );
}
export default ValuesCard;