import classes from "./priceDetails.module.css";
import icons from '../../../assets/data/priceDetailsIcons'
function PriceDetails(props) {
    return (
        <div className={classes.container}>
            <h3 className={`${props.includes? classes.includes : classes.excludes}`}>{props.includes? "Includes" : "Excludes"}</h3>
            <ul className={`${props.includes? classes.includesItem : classes.excludesItem} ${classes.details}`}>
                {props.items.map((item) => {return(
                    <li>
                        <img src={icons[item.icon]} alt="icon"/>{item.data}
                    </li>
                )})}
            </ul>
            
        </div>
    );
}

export default PriceDetails;