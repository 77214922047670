import React from "react";
import classes from "./adminCounter.module.css";
import GenericForm from "../../../generic components/generic form/GenericForm";
import axios from "../../../requests/axios";
import routes from "../../../requests/routes";
import FormikForm from "../../../generic components/form/form";
import { getInitialValues } from "../../../generic components/form/formUtils";

const AdminCounter = () => {

    const counterModel = {
        volunteers: {
            type: Number,
            label: "Travelers",
            require: true,
            row: 1,
        },
        countries: {
            type: Number,
            label: "Countries",
            require: true,
            row: 2,
        },
        programs: {
            type: Number,
            label: "Programs",
            require: true,
            row: 3,
        },
    }
    const initialValues = getInitialValues(counterModel);


    const onSubmit = async (values, { setSubmitting, setValues }) => {
        const counters = await axios.patch(routes.counterEdit, values);
        setSubmitting(false);
        setValues(initialValues);
        // window.location.reload();
    };
    return (
        <div style={{ maxWidth: "70vw", margin: 'auto' }}>
            <h1 className="adminTitle">Counter</h1>
            <div className={classes.counterContainer}>
                <FormikForm 
                    model={counterModel}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    submitMsg="Submit"
                    orderByRows={true}
                />
            </div>
        </div>
    );
}

export default AdminCounter;
