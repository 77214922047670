import visa from "../imgs/icons/priceDetails/visa.svg";
import person from "../imgs/icons/priceDetails/person.svg";
import accommodation from "../imgs/icons/priceDetails/accomodation.svg";
import fees from "../imgs/icons/priceDetails/fees.svg";
import plane from "../imgs/icons/priceDetails/plane.svg";
import transportation from "../imgs/icons/priceDetails/transportation.svg";
import support from "../imgs/icons/priceDetails/support.svg";
import training from "../imgs/icons/priceDetails/training.svg";
import activity from "../imgs/icons/priceDetails/activity.svg";
import certificate from "../imgs/icons/priceDetails/certificate.svg";
import food from "../imgs/icons/priceDetails/food.svg";
import bullet from "../imgs/icons/priceDetails/bullet.svg";

const icons = [
    visa,
    accommodation,
    plane,
    person,
    fees,
    transportation,
    support,
    training,
    activity,
    certificate,
    food,
    bullet,
];

export default icons;

export const iconsAdmin = [
    { value: 0, label: "Visa", icon: visa },
    { value: 1, label: "Accommodation", icon: accommodation },
    { value: 2, label: "Plane", icon: plane },
    { value: 3, label: "Person", icon: person },
    { value: 4, label: "Fees", icon: fees },
    { value: 5, label: "Transportation", icon: transportation },
    { value: 6, label: "Support", icon: support },
    { value: 7, label: "Training", icon: training },
    { value: 8, label: "Activity", icon: activity },
    { value: 9, label: "Certificate", icon: certificate },
    { value: 10, label: "Food", icon: food },
    { value: 11, label: "Bullet", icon: bullet },
];