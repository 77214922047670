import React, { useState } from "react";
import classes from "./programSidebar.module.css";
import { BiChevronLeft } from "react-icons/bi";
import { CiMenuBurger } from "react-icons/ci";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ProgramSidebar = (props) => {
  const { list } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const parts = location.pathname.split("/");
  const lastPart = parts[parts.length - 1];
  const [selected, setSelected] = useState("/" + lastPart);
  const [openSideBar, setOpenSideBar] = useState(false);

  const handleroute = (route) => {
    if (!list.some((item) => item.route === selected)) {
      setSelected(route);
      navigate(location.pathname + route);
      return;
    }
    if (selected !== "") {
      let now = selected;
      setSelected(route);
      const newRoute =
        location.pathname.substring(0, location.pathname.lastIndexOf(now)) +
        route;
      navigate(newRoute, { replace: true });
    }
  };

  return (
    <>
      <div
        className={classes.burger}
        onClick={() => setOpenSideBar(!openSideBar)}
      >
        <CiMenuBurger className={classes.icon} />
      </div>
      <div className={!openSideBar ? classes.mainHide : classes.main}>
        <div className={classes.eventDescription}>
          <div className={classes.backbutton}>
            <div>
              {" "}
              <BiChevronLeft size={24} />
            </div>
            <Link to="/admin/programs" className={classes.backbuttontext}>
              Programs
            </Link>
          </div>
        </div>
        <div>
          <div>
            <ol className={classes.eventMenu}>
              {list.map((program, index) => {
                return (
                  <li
                    className={classes.eventmenuitems}
                    onClick={() => handleroute(program.route)}
                  >
                    <div className={classes.circle}>{index + 1}</div>
                    {program.title}
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
        <div>
          <div>
            <ul className={classes.eventMenu}></ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramSidebar;
