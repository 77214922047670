import * as Yup from "yup";

// type modelItem = {
//     type: String | File | Date | number | boolean;
//     required?: Boolean;
//     min?: Number;
//     max?: Number;
//     pattern?: RegExp;
// }

const typeToYup = (type) => {
    switch (type){
        case String:
            return Yup.string();
        case Number:
            return Yup.number();
        case Boolean:
            return Yup.boolean();
        case Date:
            return Yup.date();
        case File:
            return Yup.mixed();
        default:
            return Yup.string();
    }
};
const CreateYupSchema = (model) =>{
    const schemaDict = {};
    for (const key in model){
        const item = model[key];

        if ( Array.isArray(item) ){
            if (item[0].type){
                schemaDict[key] = Yup.array().of(typeToYup(item[0].type));
                continue;
            }
            schemaDict[key] = Yup.array().of(CreateYupSchema(item.model));
            continue;
        }

        let schema = typeToYup(item.type);
        
        if (item.require){
            schema = schema.required(`${key} is required`);
        }
        if (item.min){
            schema = schema.min(item.min, `${key} must be ${item.len} characters at minimum`);
        }
        if (item.max){
            schema = schema.max(item.max, `${key} must be ${item.len} characters at maximum`);
        }
        if (item.pattern){
            schema = schema.matches(item.pattern, `${key} is not valid`);
        }
        if (item.gt){
            schema = schema.min(Yup.ref(item.gt), `${key} must be greater than ${item.gt}`);
        }
        if (item.lt){
            schema = schema.max(Yup.ref(item.lt), `${key} must be less than ${item.lt}`);
        }
        schemaDict[key] = schema;
    }

    return Yup.object().shape(schemaDict);
};

export default CreateYupSchema;
