import React from "react";
import FormikForm from "../../generic components/form/form";
import { getInitialValues } from "../../generic components/form/formUtils";
import { FormModel } from "./FormModel";

const DummyForm = () => {

  const initialValues = getInitialValues(FormModel);
  const onSubmit = async (values, { setSubmitting, setValues }) => {
    setSubmitting(false);
    setValues(initialValues);
    window.location.reload();
  };

  return (
    <div style={{ maxWidth: "70vw"}}>
      <FormikForm
        model={FormModel}
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitMsg="Next"
        orderByRows={true}
      />
    </div>
  );
};

export default DummyForm;
