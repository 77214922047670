import React from 'react'
import classes from '../profile.module.css'
import EventList from "../../../generic components/Event List/EventList";
import notFound from "../../../assets/imgs/notFound/noWishlist.png";

const MyWishlist = (props) => {
  return (
    <div>
      <section>
        {props.user.wishlist?.length > 0 ?
          <div className={classes.eventList}>
            <EventList title={false} events={props.user.wishlist} wishlist={props.wishlist} />
          </div> :
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <img alt="notFound" style={{ maxWidth: "60vw" }} src={notFound} />
            <p style={{ fontSize: "3rem", color: "var(--green)", marginBlockEnd: "3rem", maxWidth: "90vw", textAlign: "center" }}>Like programs to keep them safe in your wishlist!</p>
          </div>
        }
      </section>
    </div>
  )
}

export default MyWishlist