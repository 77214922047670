
export function setValueByStringKey(obj, str, newValue) {
    const keys = str.split('.').map(key => {
        const startIndex = key.indexOf('[');
        if (startIndex !== -1) {
            const endIndex = key.indexOf(']');
            const index = parseInt(key.slice(startIndex + 1, endIndex));
            return { key: key.slice(0, startIndex), index };
        } else {
            return { key };
        }
    });

    let currentObj = obj;
    for (let i = 0; i < keys.length; i++) {
        const { key, index } = keys[i];
        if (i === keys.length - 1) {
            if (index !== undefined) {
                currentObj[key][index] = newValue;
            } else {
                currentObj[key] = newValue;
            }
        } else {
            currentObj = index !== undefined ? currentObj[key][index] : currentObj[key];
        }
    }
}

export function accessNestedValue(obj, key) {
    let keyParts = key.split('.');
    let value = obj;
    let parent = null;
    let propertyName = null;

    // Iterate through the parts to access the nested values
    for (let part of keyParts) {
        parent = value;
        propertyName = part;

        // Check if the part contains an array index
        if (part.includes('[')) {
            let index = parseInt(part.match(/\d+/)[0]); // Extract the index
            propertyName = part.split('[')[0]; // Extract the property name
            value = value[propertyName][index];
        } else {
            value = value[part];
        }
    }

    return { parent, propertyName };
}
