import React, { useEffect, useState } from "react";
import classes from "./programList.module.css";
import axios from "../../../../requests/axios";
import routes from "../../../../requests/routes";
import moment from "moment";
import Loader from "../../../../layouts/loader/Loader";


const ProgramList = () => {
  const [adminPrograms, setAdminPrograms] = useState([]);
  const [loader,setLoader] = useState(true);

  async function getPrograms() {
    try {
      const response = await axios.get(routes.getAdminPrograms);
      setAdminPrograms(response.data.programs);
      setLoader(false);
    } catch (err) {}
  }

  function onHandleClick(id, event) {
    event.preventDefault();
    window.location.href = `/admin/programs/${id}/`;
  }

  useEffect(() => {
    getPrograms();
  }, []);

  return (
    <div className={classes.programsTable}>
      {loader ? <Loader color={"#F46444"}/> :
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Slots</th>
            <th>Type</th>
            <th>Slots Available</th>
            <th>Rating</th>
            <th>Home</th>
            <th>Published</th>
          </tr>
        </thead>
        <tbody>
          {adminPrograms.map((program) => (
            <tr onClick={(event)=>onHandleClick(program._id,event)}>
              <td>{program.name}</td>
              <td>{program.location}</td>
              <td>{moment(program.startDate).format("D/M/YY")}</td>
              <td>{moment(program.endDate).format("D/M/YY")}</td>
              <td>{program.slots.length}</td>
              <td>{program.type}</td>
              <td>{program.spotsAvailable}</td>
              <td>{Number(program.rating).toFixed(2)}</td>
              <td>{program.isHomepage?"Yes":"No"}</td>
              <td>{program.isPublished?"Yes":"No"}</td>
            </tr>
          ))}
        </tbody>
      </table>}
    </div>
  );
};

export default ProgramList;
