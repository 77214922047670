import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import countryFlags from "../../../assets/data/countryFlags";
import MyToaster from "../../../generic components/toaster/MyToaster.js";
import axios from "../../../requests/axios";
import routes from "../../../requests/routes";
import classes from "./personalInfo.module.css";

const PersonalInfo = (props) => {
  const user = props.user;
  var DoB = new Date(user?.birthDate);

  const [isLoading,setIsLoading] = useState(false)

//yyyy-MM-dd
  var DoBString = DoB?.getFullYear() + "-" + (DoB?.getMonth() + 1) + "-" + DoB?.getDate();

  const initialValues = {
    email: user?.email,
    fullName: user?.fullName,
    phoneNO: user?.phoneNO,
    nationalId: user?.nationalId,
    birthDate: user?.birthDate?DoBString:"",
    profession: user?.profession,
    gender:  user?.gender,
    travelledBefore: user?.travelledBefore,
    country: user?.nationality,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(3)
      .email("Please enter a valid email address")
      .required("Please enter a valid email address"),
  });
  const handleSubmit = (data, { setErrors }) => {
    setIsLoading(true);
    var dataToSend = new FormData();
    dataToSend.append("phoneNO", data.phoneNO);
    dataToSend.append("birthDate", data.birthDate);
    dataToSend.append("profession", data.profession);
    dataToSend.append("nationalId", data.nationalId);
    dataToSend.append("gender",data.gender);
    dataToSend.append("travelledBefore",data.travelledBefore);
    dataToSend.append("nationality",data.country);
    dataToSend.append("image",user?.image);

    async function updateData() {
      try {
        await axios.put(routes.getUser, dataToSend);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        return Promise.reject(error);
      }
    }

    //updateData();

    toast.promise(updateData(), {
      loading: "Loading",
      success: "Data updated successfully",
      error: "Error updating data",
    });
    props.setProceed(3)
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <div className={
                !props.isBooking ? classes.container : null
              }>
            <div
              className={
                props.isBooking ? classes.personalInfo : null
              }
            >
              <div className={classes.personalInfoSection}>
                {props.isBooking ? (
                  <>
                    <h5 className={classes.infoTitle}> Personal Information</h5>
                    <h6 className={classes.subtitle}>
                      Update your personal information if needed
                    </h6>
                  </>
                ) : null}

                <div className={classes.fieldRow}>
                  <div className={classes.fieldEntry}>
                    <label className={classes.label}> Full Name</label>
                    <Field
                      className={classes.field}
                      name="fullName"
                      type="text"
                      autoComplete="off"
                      required
                      readOnly
                    />
                    <ErrorMessage
                      name="fullName"
                      component="span"
                      className={classes.error}
                    />
                  </div>
                  <div className={classes.fieldEntry}>
                    <label className={classes.label}> Email Address</label>
                    <Field
                      className={classes.field}
                      name="email"
                      autoComplete="off"
                      data-testid="LoginFormEmailInput"
                      required
                      readOnly
                    />
                    <ErrorMessage
                      className={classes.error}
                      name="email"
                      component="span"
                      data-testid="emailError"
                    />
                  </div>
                </div>
                <div className={classes.fieldRow}>
                  <div className={classes.fieldEntry}>
                    <label className={classes.label}> National ID</label>
                    <Field
                      className={classes.field}
                      name="nationalId"
                      pattern="^[0-9]{14}$"
                      autoComplete="off"
                      number="true"
                    />
                    <ErrorMessage
                      className={classes.error}
                      name="nationalId"
                      component="span"
                    />
                  </div>

                  <div className={classes.fieldEntry}>
                    <label className={classes.label}> Phone Number</label>
                    <Field
                      className={classes.field}
                      name="phoneNO"
                      autoComplete="off"
                      data-testid="LoginFormEmailInput"
                    />
                    <ErrorMessage
                      name="phoneNO"
                      component="span"
                      data-testid="emailError"
                    />
                  </div>
                </div>
                <div className={classes.fieldRow}>
                  <div className={classes.fieldEntry}>
                    <label className={classes.label}> Date Of Birth</label>
                    <Field
                      className={classes.field}
                      name="birthDate"
                      type="date"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      name="birthDate"
                      component="span"
                      className={classes.error}
                    />
                  </div>
                  <div className={classes.fieldEntry}>
                    <label className={classes.label}>Profession</label>
                    <Field
                      className={classes.field}
                      name="profession"
                      type="text"
                      autoComplete="off"
                    />
                    <ErrorMessage
                      name="profession"
                      component="span"
                      className={classes.error}
                    />
                  </div>
                </div>
                <div className={classes.fieldRow}>
                    <div className={classes.fieldEntry}>
                    <label className={classes.label}>Country</label>
                          <Field
                            as="select"
                            name="country"
                            className={classes.field}
                            defaultValue={values.country}
                          >
                            {countryFlags.map((country) => {
                              return (
                                <option
                                  value={country.country}
                                  className={classes.dropItem}
                                >
                                  {country.country}
                                </option>
                              );
                            })}
                          </Field>
                    </div>
                </div>
                <div className={classes.fieldRow}>
                  <div className={classes.fieldEntry}>
                    <label className={classes.label}>Gender</label>
                    <div className={classes.list}>
                      <label className={classes.label__radio}>
                        <Field
                          type="radio"
                          className={classes.radio}
                          name="gender"
                          value="male"
                        />
                        <div>Male</div>
                      </label>
                      <label className={classes.label__radio}>
                        <Field
                          type="radio"
                          className={classes.radio}
                          name="gender"
                          value="female"
                        />
                        <div>Female</div>
                      </label>
                    </div>
                  </div>
                  <div className={classes.fieldEntry}>
                    <label className={classes.travelled}>
                      <Field
                        type="checkbox"
                        name="travelledBefore"
                        className={classes.checkbox}
                      />
                      <div className={classes.label}>
                        Travelled with Have A Dream before
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <button className={props.isBooking?classes.btn:classes.btnProfile} type="submit" >
            <div className={classes.submitLoader} disabled={!isLoading}></div>
              <div className={isLoading&&classes.isSubmit}><div className={classes.btnLabel}>Save <h6>→</h6></div></div>
            </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
