import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import stars from '../../../assets/imgs/icons/special request/stars.svg';
import Overlay from "../../../generic components/overlay/Overlay.tsx";
import classes from "./specialRequest.module.css";

import { ErrorMessage, Field, Form, Formik } from "formik";
import toast from "react-hot-toast";
import MyToaster from "../../../generic components/toaster/MyToaster.js";
import axios from "../../../requests/axios";
import routes from "../../../requests/routes";

const destinations = [
  "Africa",
  "Asia",
  "Australia",
  "Europe",
  "North America",
  "South America",
];

const types = [
  "Group",
  "Family",
  "Couples",
  "Solo",
  "Honeymoon",
  "Business",
  "Other",
];

export default function SpecialRequest() {
  const [open, setOpen] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    destination: destinations[0],
    type: types[0],
    description: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("enter your first name"),
    email: Yup.string()
      .min(3, "Please enter a valid email address")
      .email("Please enter a valid email address")
      .required("Please enter a valid email address"),

    phoneNo: Yup.string()
      .min(11, "enter a valid phone number")
      .matches(/^(01)[0-9]{9}$/, "enter a valid phone number")
      .required("enter your phone number"),
  });


  async function handleSubmit(data, { resetForm }) {
    async function sendRequest() {
      try {
        await axios.post(routes.specialRequest, data);
        resetForm(initialValues);
      } catch (err) {
        console.log(err);
      }
      setOpen(false);
    }
    toast.promise(sendRequest(), {
      loading: "Loading",
      success: "Your request has been sent!",
      error: "Error occurred",
    });

  }

  return (
    <div className={classes.specialRequest}>
      <h1>
        Have a Special Request?
      </h1>
      {/* <div class={classes.icons}>
        <div className={classes.icon} data-number={1} >
          <img  src={suitcase} alt="suitcase" />
        </div>
        <div className={classes.icon}>
          <img  src={hiking} alt="hiking" />
        </div>
      </div> */}
      <div className={classes.buttonContainer}>
        <p>Envision your perfect escape,<br /> Have a Dream is here to make it happen!</p>
        <div className={classes.btnX}>
          <button onClick={() => setOpen(true)} className="button">
            Get in Touch
          </button>
        </div>
      </div>

      <Overlay
        header="For special requests"
        open={open}
        onClose={() => setOpen(false)}
        position="right"
        width="50rem"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form className={classes.form}>
              <div className={classes.boxContainer}>
                <div className={classes.nameFields}>
                  <div className={classes.name}>
                    <label className={classes.label}>
                      First Name <span>*</span>
                    </label>
                    <Field
                      className={classes.field}
                      name="firstName"
                      type="text"
                      autoComplete="off"
                    />
                    <ErrorMessage name="firstName" component="span" />
                  </div>
                  <div className={classes.name}>
                    <label className={classes.label}>Last Name</label>
                    <Field
                      className={classes.field}
                      name="lastName"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className={classes.nameFields}>
                  <div className={classes.name}>
                    <label className={classes.label}>
                      Email <span>*</span>
                    </label>
                    <Field
                      className={classes.field}
                      name="email"
                      type="email"
                      autoComplete="off"
                    />
                    <ErrorMessage name="email" component="span" />
                  </div>
                  <div className={classes.name}>
                    <label className={classes.label}>
                      Phone Number <span>*</span>
                    </label>
                    <Field
                      className={classes.field}
                      name="phoneNo"
                      type="text"
                      autoComplete="off"
                    />
                    <ErrorMessage name="phoneNo" component="span" />
                  </div>
                </div>
                <div>
                  <label className={classes.label}>
                    Select your Preferred Destination <span>*</span>
                  </label>
                  <Field
                    as="select"
                    name="destination"
                    className={classes.field}
                  >
                    {destinations.map((destination) => {
                      return <option value={destination}>{destination}</option>;
                    })}
                  </Field>
                </div>

                <div>
                  <label className={classes.label}>
                    Type of your Trip <span>*</span>
                  </label>
                  <Field as="select" name="type" className={classes.field}>
                    {types.map((type) => {
                      return (
                        <option
                          value={type}
                        // className="dropItem"
                        >
                          {type}
                        </option>
                      );
                    })}
                  </Field>
                </div>

                <div>
                  <label className={classes.label}>
                    Description of requested Trip
                  </label>
                  <Field
                    as="textarea"
                    name="description"
                    placeholder="Description..."
                    className={classes.field}
                  />
                </div>

                <p>
                  For information on how to unsubscribe, as well as our privacy
                  practices and commitment to protecting your privacy, please
                  review our Privacy Policy.
                </p>
              </div>

              <div className={classes.btn}>
                <button type="submit" className="button">
                  Send
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Overlay>
    </div>
  );
}
