import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import CallendarIcon from "../../../assets/imgs/icons/callendar.png";
import mouse from "../../../assets/imgs/icons/mouse.png";
import sDollar from "../../../assets/imgs/icons/sDollar.png";
import classes from "./bookingModal.module.css";

const SlotsModal = (props) => {
  const [choice, setChoice] = useState();
  const [openDurations, setOpenDurations] = useState(false);
  const [slotIndex, setSlotIndex] = useState(0);
  const [error, setError] = useState("");

  const [selectedDuration, setSelectedDuration] = useState(
    props.slots[0].duration + " Days"
  );


  const handleSelectDuration = (duration, index) => {
    setSelectedDuration(duration+" Days");
    setOpenDurations(false);
    setSlotIndex(index);
  };
  const initialValues = {
    slotChoice: "",
  };

  const handleSubmit = (data) => {
    if (data.slotChoice === "") {
      setError("Please select a slot");
      return;
    }
    
    props.setProceed(2)
  };


  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <div className={classes.slotsInfo}>
              <div className={classes.availableSlots}>
                <h5 className={classes.slotTitle}> Available Slots</h5>
                <h6 className={classes.subtitle}>
                  <div className={classes.imgIcon}>
                    <img src={mouse} alt="Location Icon" />
                  </div>
                  Select your most suitable slots
                </h6>

                <ul className={classes.slotsList}>
                  {props?.slots[slotIndex]?.dates.map((slot) => (
                    <label className={classes.slotComponent} key={slot.id}>
                      <Field
                        type="radio"
                        name="slotChoice"
                        value={props?.slots[slotIndex]?._id}
                        className={classes.field}
                        onClick={() => {
                          setChoice(values.slotChoice);
                        }}
                      />
                      <div className={classes.fromTo}>
                        <div className={classes.fromToDate}>
                          <p>From:</p>
                          <p>{moment(slot.startDate).format("DD/MM/YYYY")}</p>
                        </div>
                        <div className={classes.fromToDate}>
                          <p>To:</p>
                          <p>{moment(slot.endDate).format("DD/MM/YYYY")}</p>
                        </div>
                      </div>
                      <div className={classes.DurationPrice}>
                        <div className={classes.DurationPriceItem}>
                          <div className={classes.imgIcon}>
                            <img src={CallendarIcon} alt="Location Icon" />
                          </div>
                          <p>{props?.slots[slotIndex].duration} Day</p>
                        </div>
                        <div className={classes.DurationPriceItem}>
                          <div className={classes.imgIcon}>
                            <img src={sDollar} alt="Location Icon" />
                          </div>
                          <p>{props?.slots[slotIndex].price}</p>
                        </div>
                      </div>
                    </label>
                  ))}
                </ul>
              </div>
              <div className={classes.duration}>
                <h5 className={classes.durationTitle}> Duration</h5>
                <div className={classes.option}>
                  <div className={classes.durationOption}>
                    <h6>{selectedDuration}</h6>
                    <div
                      className={
                        openDurations
                          ? classes.downArrowActive
                          : classes.downArrow
                      }
                      onClick={() => {
                        setOpenDurations(!openDurations);
                      }}
                    >
                      <KeyboardArrowDownIcon
                        sx={{ fontSize: "2.2rem", color: "#2b4d4d" }}
                      />
                    </div>
                  </div>
                  {openDurations && (
                    <ul className={classes.durationOptions}>
                      {props.slots.map((d, index) => (
                        <li
                          onClick={() => {
                            handleSelectDuration(d.duration, index);
                          }}
                        >
                          {d.duration} Days
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.error}>{error}</div>
            <button className={classes.btn} type="submit">
              Proceed <h6>→</h6>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SlotsModal;
