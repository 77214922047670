import impact from "../imgs/icons/values/values-01.png";
import heart from "../imgs/icons/values/values-02.png";
import sign from "../imgs/icons/values/values-05.png";
import globe from "../imgs/icons/values/values-04.png";
import community from "../imgs/icons/values/values-03.png";


const  valuesData = [
  {
    _id: 0,
    title: "Adventure",
    description: "Thrilling activities, breathtaking landscapes. Create unforgettable memories beyond your comfort zone",
    icon: <img src={sign}/>,
  },
  {
    _id: 1,
    title: "International experience",
    description: " Immerse in diverse cultures, connect with locals, and broaden your horizons. Travel with purpose",
    icon:<img src={globe}/>,
  },  
  {
    _id: 2,
    title: "Impact",
    description: " Leave a positive footprint, support local communities. Choose sustainable adventures that give back",
    icon: <img src={impact}/>,
  },  
  {
    _id: 3,
    title: "Sharing happiness",
    description: "Connect with fellow travelers, build lasting friendships. Share stories, tips, and unforgettable experiences",

    icon: <img src={heart}/>,
  },
  {
    _id: 4,
    title: "Community",
    description: "Join a vibrant travel community, find support and inspiration. Explore the world together",
    icon: <img src={community}/>,
  },
  
];

export default valuesData;
