import classes from "./priceCard.module.css";
import moment from "moment";

function PriceCard(props) {
  return (
    <div className={classes.card}>
      <div className={classes.cardHeader}>{props.title}</div>
      <div className={classes.cardBody}>
        <div className={classes.fromSec}>
          {props.duration?.map((item) => {
            return (
              <div className={classes.fromTo}>
                <h1>From: </h1>
                <p>{moment(item.startDate).format("DD/MM/yyyy")}</p>
              </div>
            );
          })}
        </div>
        <div className={classes.toSec}>
          {props.duration?.map((item) => {
            return (
              <div className={classes.fromTo}>
                <h1>To: </h1>
                <p>{moment(item.endDate).format("DD/MM/yyyy")}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.cardFooter}>
        <div className={classes.price}>
          <p className={classes.priceValue}>{props.currency + props.price}<span> &nbsp; per person </span></p>
        </div>
      </div>
    </div>
  );
}

export default PriceCard;
