import classes from './whatsappIcon.module.css';
import { WhatsApp } from '@mui/icons-material';

export default function WhatsappIcon() {
    

    return (
        <div className={classes.container} >
            <a target="_blank" rel="noreferrer noopener" href="https://wa.me/201094414168">
                <WhatsApp className={classes.icon} />
            </a>
        </div>
    )
}
