const FooterData = [
  {
    "header": "About Have A Dream",
    "links": [
      { "title": "About", "link": "/about" },
      { "title": "FAQ", "link": "/faq" },
      // { "title": "Events", "link": "/events" },
      { "title": "Terms & conditions", "link": "/terms&conditions" },
      { "title": "Privacy Policy", "link": "/policy" },
    ]
  },
  {
    "header": "Join Us",
    "links": [
      { "title": "Programs", "link": "/programs" },
      // { "title": "Sponsers", "link": "/sponsers" },
      // { "title": "Partners", "link": "/partners" },
    ]
  },
  {
    "header": "Quick Links",
    "links": [
      { "title": "Contact us", "link": "/contact" },
      // { "title": "Careers", "link": "/careers" },
      // { "title": "Blogs", "link": "/blogs" },
    ]
  },
];

export default FooterData;
