import classes from "./values.module.css";
import ValuesCard from "./valuesCard/ValuesCard";
import valuesData from "../../../assets/data/valuesData";


function Values() {
    return (
        <div className={classes.container}>
            <h3 className={classes.header}>Our Values</h3>
            <div className={classes.cardsWrapper}>
                {valuesData.map( (value) => {
                    return(
                        <ValuesCard key={value._id} icon={value.icon} title={value.title} description={value.description}/>
                    )
                })}
            </div>
            <div className={classes.background}></div>
        </div>
      );
}

export default Values;