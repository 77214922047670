import axios from "axios";
import toast from "react-hot-toast";

const instance = axios.create({
  baseURL: "https://have-a-dream-lemon.vercel.app/",
});

instance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.ID = sessionStorage.getItem("ID");
    }

    return config;
  },
  (error) => {

    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response?.data?.message)
      toast.success(response.data.message);
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      toast.error("Session Expired. Try logging in again");
      // window.location.href = "/login";
    } else
      toast.error(error.response.data.message);
    return Promise.reject(error);
  }
);


export default instance;
