import React from "react";
import { useState, useEffect } from "react";
import EventCard from "../event card/EventCard";
import classes from "./programList.module.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import EventList from "../Event List/EventList";
import tripsTypes from "../../assets/data/tripsTypes";

const ProgramList = (props) => {
  const ProgramCards = props.events;

  return (
    <div className={classes.main}>
      <div className={classes.secHeader}>{props.title && <h3>Current Programs</h3>}</div>
      <div className={classes.container}>
        {ProgramCards?.map((card) => (
          <EventCard
            id={card._id}
            key={card._id}
            img={card.image}
            title={card.name}
            date={card.startDate}
            duration={card.duration}
            location={card.location}
            price={card.price}
            isRecurrent={card.isRecurrent}
            load={props.load}
            type={tripsTypes[card.type]?.name}
            review={props.review}
            wishlist={props.wishlist}
          />
        ))}
        {!props.load && (
          <Link to="/programs">
          <div className={classes.seeMore}>
              <div className={classes.content}>
                <p>See More</p>
                <ArrowForwardIosIcon
                  style={{ fontSize: "20" }}
                  className={classes.arrow}
                />
              </div>
          </div>
          </Link>
        )}
      </div>
      <div className={classes.alice}>
          <EventList events={ProgramCards} title={false} load = {props.load}/>
          <Link to="/programs" className={classes.linkButton}>
          <div className={classes.seeMore}>
              <div className={classes.content}>
                <p>See More</p>
                <ArrowForwardIosIcon
                  style={{ fontSize: "20" }}
                  className={classes.arrow}
                />
              </div>          
          </div>
          </Link>
      </div>
    </div>
  );
};

export default ProgramList;
