import React from "react";
import toast from "react-hot-toast";
import { GiPassport } from "react-icons/gi";
import { IoStarHalfOutline } from "react-icons/io5";
import { MdAirlineSeatReclineNormal } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import MyToaster from "../../../../generic components/toaster/MyToaster";
import axios from "../../../../requests/axios";
import routes from "../../../../requests/routes";
import StatCard from "../../dashboard/stat card/StatCard";
import classes from "./programMain.module.css";

const ProgramMain = (props) => {
  const navigate = useNavigate();
  const id = props?.program?._id;


  const handleDelete = () => {
    async function getProgram() {
      try {
        await axios.delete(routes.getPrograms + `/${id}`);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    toast.promise(getProgram(), {
      loading: "Deleting...",
      success: "Program deleted",
      error: "Error deleting program",
    });
  };

  return (
    <div>
      <h1 className="adminTitle">Main</h1>
      <div className={classes.stats}>
        <StatCard
          title="Bookings"
          number={10}
          icon=<GiPassport className={classes.icon} />
        />
        <StatCard
          title="Available"
          number={props?.program?.spotsAvailable}
          icon=<MdAirlineSeatReclineNormal className={classes.icon} />
        />
        <StatCard
          title="Rating"
          number={Number(props?.program?.rating).toFixed(2)}
          icon=<IoStarHalfOutline className={classes.icon} />
        />
      </div>
      <div className={classes.controlUnit}>
        <button
          onClick={() => {
            navigate(`/programs/${id}`);
          }}
        >
          View
        </button>
        <button onClick={handleDelete}> Delete </button>
      </div>
      {/* <div className={classes.requests}>
        <h1>Requests</h1>
        <ProgramsList />
      </div> */}
    </div>
  );
};

export default ProgramMain;
