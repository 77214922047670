import React from "react";
import classes from "../form.module.css";
import { renderFieldsArray, renderFields, getInitialValues, initialValuesForFields } from "../formUtils";
import { accessNestedValue } from "../../../utils/generalUtils";


function AddableForm(props) {
    const { model, name, orderByRows, values, setValues } = props;

    const initialValues = Object.keys(model).length > 1 ? getInitialValues(model) : initialValuesForFields(model['null']['type']);
    const modelFields = Object.fromEntries(
        Object.entries(model).filter(([_, v]) => !Array.isArray(v))
    )
    const modelArrayFields = Object
        .entries(model).filter(([_, v]) => Array.isArray(v))

    const onRemove = (index) => {
        setValues(prev => {
            const newValues = { ...prev };
            let { parent, propertyName } = accessNestedValue(newValues, name)
            parent[propertyName] = parent[propertyName].filter((_, i) => i !== index);
            return newValues;
        })
    }

    const onAdd = () => {
        setValues(prev => {
            const newValues = { ...prev };
            if (!newValues[name]) {
                newValues[name] = []
            }
            let { parent, propertyName } = accessNestedValue(newValues, name)
            parent[propertyName].push(initialValues);
            return newValues;
        })
    }

    const renderAddableForm = (modelFields, modelArrayFields, orderByRows, name, index, setValues) => {
        if (modelArrayFields.length === 0) {
            return (
                <div key={index} className={classes.addableForm}>
                    {renderFieldsArray(modelFields, orderByRows, name, index, setValues)}
                    <button type="button" onClick={() => onRemove(index)}>-</button>
                </div>
            )
        }
        return (
            <div className={classes.addableFormNested}>
                {renderFields(modelFields, orderByRows, setValues, `${name}[${index}]`)}
                {modelArrayFields.map(([key, value]) => {
                    return (
                        <div key={key} className={classes.arrayFields}>
                            <h2>{key}</h2>
                            <AddableForm key={key}
                                model={value[0]}
                                name={`${name}[${index}].${key}`}
                                orderByRows={orderByRows}
                                values={values}
                                setValues={setValues}
                            />
                        </div>
                    )
                })}
                <button type="button" onClick={() => onRemove(index)}>-</button>
            </div>
        )
    }
    const formatName = (name) => {
        if (!name.includes('.'))
            return name;

        const names = name.split('.')
        return names[names.length - 1]
    }
    let { parent, propertyName } = accessNestedValue(values, name)
    return (
        <>
            <div className={classes.addHint}>Click + to add {formatName(name)}<button className={classes.addButton} type="button" onClick={onAdd}>+</button></div>
            {
                parent[propertyName]?.map((_, index) => {
                    return (
                        <React.Fragment key={index}>
                            {renderAddableForm(modelFields, modelArrayFields, orderByRows, name, index, setValues)}
                        </React.Fragment>
                    )
                })
            }

        </>
    )
}

export default AddableForm;
