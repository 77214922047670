import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import locationIcon from "../../../assets/imgs/icons/location.png";
import axios from "../../../requests/axios";
import routes from "../../../requests/routes";
import DummyForm from "../../dummy form/DummyForm";
import PersonalInfo from "../../profile/Personal Information/PersonalInfo";
import classes from "./bookingModal.module.css";
import SlotsSection from "./SlotsSection";

const BookingModal = (props) => {
  const [statebtn, changestatebtn] = useState(true);
  const [proceed, setProceed] = useState(1);
  const myUser = useSelector((state) => state.user);
  const id = myUser.id;
  const [user, setUser] = useState({});

  const handleClose = () => {
    changestatebtn(false);
    props.setOpenBooking(false);
  };
  const handleBack = () => {
    setProceed(proceed - 1);
  };

  async function getUser() {
    try {
      const response = await axios.get(routes.getUser + id);
      setUser(response.data);
    } catch (err) {}
  }
  useEffect(() => {
    getUser();
  }, []);

  return (
    <Modal
      open={statebtn}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.genericmodal}
    >
      <div className={classes.infoBox}>
        <div className={classes.info}>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose();
            }}
            className={classes.modalclose}
          >
            <CloseIcon className={classes.btnclose} />
          </IconButton>
          {proceed > 1 ? (
            <IconButton
              aria-label="back"
              onClick={() => {
                handleBack();
              }}
              className={classes.modalBack}
            >
              <ArrowBackIcon className={classes.btnBack} />
            </IconButton>
          ) : null}

          <div className={classes.header}>
            <h3 className={classes.programTitle}>Program Name</h3>
            <div className={classes.programLocation}>
              <div className={classes.imgIcon}>
                <img src={locationIcon} alt="Location Icon" />
              </div>{" "}
              <h4>Location</h4>
            </div>
            <hr />
          </div>

          {/* Slots form */}
          {proceed === 1 ? <SlotsSection setProceed={setProceed} slots={props.slots}/> : null}

          {/* Personal Information from */}
          {proceed === 2 ? (
            <PersonalInfo
              isBooking={true}
              user={user}
              id={user._id}
              setProceed={setProceed}
            />
          ) : null}

          {proceed === 3 ? (
            <div className={classes.slotsInfo}>
              <div className={classes.forms}>
                <h5 className={classes.slotTitle}> Behavioral Questions</h5>
                <DummyForm />
              </div>
            </div>
          ) : null}
          {proceed === 4 ? (
            <div className={classes.slotsInfo}>
              <div className={classes.availableSlots}>
                <h5 className={classes.slotTitle}> Billing Information</h5>
                <h6 className={classes.subtitle}> Payment will be added</h6>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default BookingModal;
