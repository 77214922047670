import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import icons from "../../../assets/data/priceDetailsIcons";
import classes from "./expandablesec.module.css";

function ExpandableSection(props) {
  // const [open, setOpen] = useState(false);
  // const [height, setHeight] = useState(0);
  // const [expandedHeight, setExpandedHeight] = useState(0);

  // let busy = false;
  //
  // const headerClickHandler = async (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (busy) return;
  //   if (e.target.parentElement.open) {
  //   //   console.log(e.target.parentElement.offsetHeight);
  //   //   if (expandedHeight === 0)
  //   //     setExpandedHeight(e.target.parentElement.offsetHeight);
  //   //   e.target.parentElement.style.maxHeight = height + "px";
  //     setOpen(false);
  //     busy = true;
  //     setTimeout(() => {e.target.parentElement.removeAttribute("open"); busy = false}, 1000);
  //   } else {
  //     // if (height === 0){
  //     //   setHeight(e.target.parentElement.offsetHeight);
  //     // }
  //     setOpen(true);
  //     e.target.parentElement.open = true;
  //     // if (expandedHeight !== 0)
  //     //   e.target.parentElement.style.maxHeight = expandedHeight + "px";
  //   }
  // };

  return (
    <details className={classes.wrapper}>
      <summary className={classes.sectionTitle}>
      {icons[props.icon] && (props.aux != true) && (
          <img src={icons[props.icon]} alt="icon" className={classes.icon} />
        )}        
        <div className={classes.sectionName}>{props.title}</div>
        <ExpandMoreIcon
          className={classes.expandIcon}
          sx={{ fontSize: "4rem" }}
        />
      </summary>
      <ul className={classes.content}>
        {props.text.map((content) => (
          <>
            <li>- {content}</li>
          </>
        ))}
      </ul>
    </details>
  );
}

export default ExpandableSection;
