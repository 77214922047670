import React, { useState , useEffect} from "react";
import classes from "./terms.module.css";
import { useNavigate } from "react-router-dom";
import ExpandableSection from "../programDetails/expandableSection/ExpandableSection";
import TermsConditions from "../../assets/data/termsCondData.js";
function Terms()
// 
// By accessing and using this website, you agree to comply with the following Terms & Conditions, which together with our Privacy Policy govern Have A Dream’s relationship with you concerning this website. If you disagree with any part of these terms, please do not use our website or services.

{
return(

<div className={classes.container}>
    <h1 className={classes.pageHeader}>Terms & Conditions</h1>
    <p className={classes.para}>Welcome to Have A Dream’s website. Have A Dream Limited Liability Company, is dedicated to providing cultural, voluntary and training opportunities that empower youth to develop new skills, enhance personal growth and unlock their potential.</p>
    <p className={classes.para}>By accessing and using this website, you agree to comply with the following Terms & Conditions, which together with our Privacy Policy govern Have A Dream’s relationship with you concerning this website. If you disagree with any part of these terms, please do not use our website or services.</p>
    <section className={classes.detailsSection}>
        {TermsConditions.terms.map((term) => (
            <ExpandableSection title={term.title} aux={true} {...term} />
        ))}
    </section>
</div>

);
};
export default Terms;