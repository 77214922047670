import classes from "./highlights.module.css";
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import fallbackImg from "../../../assets/imgs/notFound/fallback-image.png";
import { useState } from "react";

function Highlights(props) {

  const { highlights } = props;
  const [images, setImages] = useState(highlights?.map((card) => card.image));

  const handleImageError = (index) => {
    setImages(images => {
      const newImages = [...images];
      newImages[index] = fallbackImg;
      return newImages;
    });
  }

  return <>
    <AliceCarousel
      className={classes.carousel}
      mouseTracking
      items={highlights?.map((card, index) => (
        <div className={classes.highlightCard} key={index}>
          <img src={images[index]} className={classes.highlightImg} alt="h" onError={() => handleImageError(index)} />
          <div className={classes.highlightTitle}>{card.title}</div>
          <div className={classes.highlightInfo}>{card.description}</div>
        </div>
      ))}
      responsive={{
        0: {
          items: 2,
          // itemsFit: 'fill'
        },
        1024: {
          items: 3,
          // itemsFit: 'fill',
        }
      }}
      disableDotsControls={true}
      renderPrevButton={(e) => { return <ArrowBackIosNewIcon style={{ fontSize: "25" }} className={e.isDisabled ? classes.carouseLBtnDis : classes.carouseLBtn} /> }}
      renderNextButton={(e) => { return <ArrowForwardIosIcon style={{ fontSize: "25" }} className={e.isDisabled ? classes.carouselRtnDis : classes.carouselRBtn} /> }}
    />
  </>;
}

export default Highlights;
