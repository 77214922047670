import React, { useEffect, useState } from "react";
import { FaRegMap } from "react-icons/fa";
import { GiWorld } from "react-icons/gi";
import { HiOutlineUsers } from "react-icons/hi2";
import { MdCardTravel, MdVolunteerActivism } from "react-icons/md";
import "../../../index.css";
import Loader from "../../../layouts/loader/Loader";
import axios from "../../../requests/axios";
import routes from "../../../requests/routes";
import classes from "./adminDashboard.module.css";
import Chart from "./charts/Chart";
import StatCard from "./stat card/StatCard";

const AdminDashboard = () => {
  const [stat, setStat] = useState({});
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(true);
  const [year, setYear] = useState("2023");

  
  const handleInputChange = (e) => {
    setYear(e.target.value);
  };
  
  useEffect(() => {
    setLoader(true);
    setUsers([]);
    async function fetchData() {
      try {
        let res = await axios.get(routes.getAdminStastics + "?year="+year);
        setStat(res.data);
        Object.keys(res.data.usersPerMonth).forEach((month) => {
          setUsers((prev) => [...prev, res.data.usersPerMonth[month]]);
        });

        console.log(users);
        setLoader(false);
      } catch (err) {}
    }
    fetchData();
  }, [year]);

  const chartData = {
    title: "Users Per Month",
    type: "bar",
    data: [
      {
        date: "Jan",
        number: users[0],
      },
      {
        date: "Feb",
        number: users[1],
      },
      {
        date: "Mar",
        number: users[2],
      },
      {
        date: "Apr",
        number: users[3],
      },
      {
        date: "May",
        number: users[4],
      },
      {
        date: "Jun",
        number: users[5],
      },
      {
        date: "Jul",
        number: users[6],
      },
      {
        date: "Aug",
        number: users[7],
      },
      {
        date: "Sep",
        number: users[8],
      },
      {
        date: "Oct",
        number: users[9],
      },
      {
        date: "Nov",
        number: users[10],
      },
      {
        date: "Dec",
        number: users[11],
      },
    ],
  };
  return (
    <div style={{ maxWidth: "70vw", margin: "auto" }}>
      <h1 className="adminTitle">Dashboard</h1>
      {loader ? (
        <Loader color={"#F46444"} />
      ) : (
        <>
          <div className={classes.counter}>
            <StatCard
              title="Countries"
              number={stat?.counts?.countries}
              icon=<GiWorld className={classes.icon} />
            />
            <StatCard
              title="Programs Home"
              number={stat?.counts?.programs}
              icon=<MdCardTravel className={classes.icon} />
            />
            <StatCard
              title="Volunteers"
              number={stat?.counts?.volunteers}
              icon=<MdVolunteerActivism className={classes.icon}/>
            />
          </div>
          <div className={classes.stats}>
            <StatCard
              title="Total Users"
              number={stat.usersCount}
              icon=<HiOutlineUsers className={classes.icon} />
            />
            <StatCard
              title="Total Programs"
              number={stat.programsCount}
              icon=<FaRegMap className={classes.icon} />
            />
          </div>
          <div className={classes.charts}>
            <div className={classes.dropDown}>
              <form>
              <select name="year" onChange={handleInputChange}>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
              </form>
            </div>
            <Chart
              type={chartData.type}
              data={chartData.data}
              width={"100%"}
              xKey="date"
              yKey="number"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
