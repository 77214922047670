import classes from "./form.module.css";
import { Field, ErrorMessage } from "formik";
import React from "react";
import axios from "../../requests/axios";
import routes from "../../requests/routes";
import { setValueByStringKey } from "../../utils/generalUtils";
import Loader from "../../layouts/loader/Loader";

export const getInitialValues = (model) => {
  return Object.fromEntries(
    Object.entries(model).map(([key, value]) => {
      return [key, initialValuesForFields(value)];
    })
  )
}
export const initialValuesForFields = (field) => {
  switch (field.type) {
    case String:
      return "";
    case "textArea":
      return "";
    case Number:
      return 0;
    case Date:
      return new Date().toISOString().split('T')[0];
    case Boolean:
      return false;
    case File:
      return undefined;
    case "dropDown":
      if (typeof field.options[0] === "object") {
        return field.options[0].value;
      }
      return field.options[0];
    default:
      return [];
  }
}
export const getType = (type) => {
  switch (type) {
    case String:
      return "text";
    case "textArea":
      return "textarea";
    case Number:
      return "number";
    case Boolean:
      return "checkbox";
    case Date:
      return "date";
    case File:
      return "file";
    case "dropDown":
      return "select";
    default:
      return "text";
  }
}

const renderField = (value, name, setValues) => {
  if (value.type === "textArea") {
    return (
      <Field
        className={classes.field}
        name={name}
        as="textarea"
      />
    )
  }

  if (value.type === File) {
    return (
      <Field
        className={classes.field}
        name={name + ".file"}
        type={getType(value.type)}
        render={({ field }) => {
          return (
            <>
              <div className={classes.field} style={
                field.value !== null ? { display: "none" } : {}}>
                <Loader />
              </div>
              <input type="file" className={classes.field} {...field}
                style={field.value === null ? { display: "none" } : {}}
                onChange={async (event) => {
                  setValues((prev) => {
                    const newValues = { ...prev };
                    setValueByStringKey(newValues, name, null)
                    return newValues;
                  })
                  const resp = await axios.post(routes.uploadImage,
                    { image: event.currentTarget.files[0] },
                    { headers: { 'Content-Type': 'multipart/form-data' } });
                  setValues((prev) => {
                    const newValues = { ...prev };
                    setValueByStringKey(newValues, name, resp.data?.image[0])
                    return newValues;
                  })
                }}
              />
            </>
          )
        }}
      />
    )
  }

  if (value.type === "dropDown") {
    return (
      <Field
        className={classes.field}
        name={name}
        as="select"
      >
        {value.options.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {typeof item === "object" ?
                <option value={item.value} key={index} >
                  {item.label}
                </option>
                :
                <option value={item} key={index} >
                  {item}
                </option>
              }
            </React.Fragment>
          )
        })}
      </Field>
    )
  }

  return (
    <Field
      className={classes.field}
      name={name}
      type={getType(value.type)}
      autoComplete="off"
    />
  )
}

export const renderFields = (model, orderByRows, setValues, name) => {
  const getFieldName = (name, key) => {
    if (name) {
      return name + "." + key;
    }
    return key;
  }
  if (orderByRows) {
    const modelArray = Object.entries(model).map(([key, value]) => {
      return {
        key: key,
        ...value
      }
    });
    const rows = Object.groupBy(modelArray, (value) => value.row);

    return (
      Object.entries(rows).map(([key, values]) => {
        return (
          <div key={key} className={classes.nameFields}>
            {values.map((value, index) => {
              return (
                <div key={index} className={classes.name}>
                  <label className={classes.label} required={value.required}>{value.label ?? value.key}</label>
                  {renderField(value, getFieldName(name, value.key), setValues)}
                  <ErrorMessage name={getFieldName(name, value.key)} component="span" />
                </div>
              )
            })}
          </div>
        )
      })
    )

  }
  else {
    return (
      Object.entries(model).map(([key, value]) => {
        return (
          <div key={key} className={classes.name}>
            <label className={classes.label} required={value.required}>{key}</label>
            {renderField(value, getFieldName(name, value.key), setValues)}
            <ErrorMessage name={getFieldName(name, value.key)} component="span" />
          </div>
        )
      })
    )
  }
}


export const renderFieldsArray = (model, orderByRows, arrName, formIndex, setValues) => {
  const getFieldName = (name, formIndex, key) => {
    if (key === "null") {
      return `${name}[${formIndex}]`;
    }
    return `${name}[${formIndex}].${key}`;
  }
  if (orderByRows) {
    const modelArray = Object.entries(model).map(([key, value]) => {
      return {
        key: key,
        ...value
      }
    });
    const rows = Object.groupBy(modelArray, (value) => value.row);

    return (
      Object.entries(rows).map(([key, values]) => {
        return (
          <div key={key} className={classes.nameFields}>
            {values.map((value, index) => {
              return (
                <div key={index} className={classes.name}>
                  {value.key !== 'null' ? <label className={classes.label} required={value.required}>{value.label ?? value.key}</label> : ""}
                  {renderField(value, getFieldName(arrName, formIndex, value.key), setValues)}
                  <ErrorMessage name={getFieldName(arrName, formIndex, value.key)} component="span" />
                </div>
              )
            })}
          </div>
        )
      })
    )

  }
  else {
    return (
      Object.entries(model).map(([key, value]) => {
        return (
          <div key={key} className={classes.name}>
            <label className={classes.label} required={value.required}>{key}</label>
            {renderField(value, getFieldName(arrName, formIndex, value.key), setValues)}
            <ErrorMessage name={getFieldName(arrName, formIndex, value.key)} component="span" />
          </div>
        )
      })
    )
  }
}
