import React, { useState, useEffect, useRef } from "react";
import classes from "./counter.module.css";
import CounterCard from "./CounterCard";
import programs from "../../../assets/imgs/icons/counter/programs.png";
import volunteer from "../../../assets/imgs/icons/counter/volunteers.png";
import countries from "../../../assets/imgs/icons/counter/countries.png";
const CounterSection = (props) => {



  return (
    <div className={classes.divCont}>
      <div className={classes.cards}>
        <CounterCard count={props.counts?.volunteers} img={volunteer} text={"Travelers"} load={props.load}/>
        <CounterCard count={props.counts?.countries} img={countries} text={"Countries"} load={props.load}/>
        <CounterCard count={props.counts?.programs} img={programs} text={"Programs"} load={props.load}/>
      </div>
    </div>
  );
};

export default CounterSection;
