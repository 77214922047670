import { iconsAdmin } from "../../../assets/data/priceDetailsIcons"
export const programModel = {
    name: {
        type: String,
        require: true,
        row: 1,
    },
    location: {
        type: String,
        require: true,
        row: 1,
    },
    type: {
        type: 'dropDown',
        require: true,
        options: [
            {
                label: "Volunteering",
                value: 0
            },
            {
                label: "Adventurous & Road trips",
                value: 1
            },
            {
                label: "Have a Dream Juniors",
                value: 2
            },
            {
                label: "EU program",
                value: 3
            },
            {
                label: "Special Requests",
                value: 4
            },
        ],
        row: 1,
    },
    image: {
        type: File,
        require: true,
        row: 2,
    },

    price: {
        type: Number,
        require: true,
        row: 2,
    },
    currency: {
        type: 'dropDown',
        require: true,
        options: [
            "USD",
            "EUR",
            "EGP",
        ],
        row: 2,
    },
    less_than_week: {
        type: Boolean,
        label: "< 1 week",
        require: false,
        row: 3,
    },
    one_to_two_Weeks: {
        type: Boolean,
        label: "1 - 2 weeks",
        require: false,
        row: 3,
    },
    two_weeks_to_month: {
        type: Boolean,
        label: "2 weeks - 1 month",
        require: false,
        row: 3,
    },
    one_to_two_months: {
        type: Boolean,
        label: "1 - 2 months",
        require: false,
        row: 3,
    },
    more_than_two_months: {
        label: "> 2 months",
        type: Boolean,
        require: false,
        row: 3,
    },
    // duration:{
    //     type: "dropDown",
    //     require: true,
    //     options: [
    //         "< week",
    //         "1 - 2 weeks",
    //         "2 weeks - 1 month",
    //         "1 - 2 months",
    //         "> 2 months",
    //     ],
    //     row: 3,
    // },
    startDate: {
        type: Date,
        require: true,
        row: 4,
    },
    endDate: {
        type: Date,
        require: true,
        gt: 'startDate',
        row: 4,
    },
    applicationDeadline: {
        type: Date,
        require: true,
        lt: 'startDate',
        row: 5,
    },
    gender: {
        type: 'dropDown',
        require: true,
        options: [
            "Male",
            "Female",
            "Any",
        ],
        row: 5,
    },
    all_ages: {
        label: "All Ages",
        type: Boolean,
        require: false,
        row: 6,
    },
    nine_to_thirteen: {
        label: "9 - 13 years",
        type: Boolean,
        require: false,
        row: 6,
    },
    fourteen_to_seventeen: {
        label: "14 - 17 years",
        type: Boolean,
        require: false,
        row: 6,
    },
    eighteen_to_twenty_five: {
        label: "18 - 25 years",
        type: Boolean,
        require: false,
        row: 6,
    },
    twenty_six_to_thirty: {
        label: "26 - 30 years",
        type: Boolean,
        require: false,
        row: 6,
    },
    thirty_to_forty: {
        label: "30 - 40 years",
        type: Boolean,
        require: false,
        row: 6,
    },
    forty_to_forty_five: {
        label: "40 - 45 years",
        type: Boolean,
        require: false,
        row: 6,
    },
    forty_six_plus: {
        label: "46+ years",
        type: Boolean,
        require: false,
        row: 6,
    },

    // age:{
    //     type: 'dropDown',
    //     require: true,
    //     options: [
    //         "All",
    //         "9 - 13",
    //         "14 - 17",
    //         "18 - 25",
    //         "26 - 30",
    //         "30 - 40",
    //         "40 - 45",
    //         "46+",
    //     ],
    //     row: 4,
    // },
    isPublished: {
        type: Boolean,
        row: 7,
    },
    isHomepage: {
        type: Boolean,
        label: "visible on homepage",
        row: 7,
    },
    isAvailable: {
        type: Boolean,
        row: 7,
    },
    isHot: {
        type: Boolean,
        label: "Hot",
        row: 7,
    },
    isRecurrent: {
        type: Boolean,
        row: 7,
    },
    summary: {
        type: "textArea",
        row: 8,
        require: true
    },
    googleForm: {
        type: String,
        label: "Google Form Link",
        row: 9,
        require: true
    },
    highlights: [{
        image: { type: File, require: true, row: 1 },
        title: { type: String, require: true, row: 1 },
        description: { type: "textArea" },
    }],
    spotsAvailable: {
        type: Number,
        require: true
    },
    // TODO: check that
    // getStarted: [{
    //     type: String,
    // }],
    // snippetsImg: [{
    //     type: String,
    // }],
    details: [{
        icon: {
            type: "dropDown",
            options: iconsAdmin,
            require: true,
            row: 1,
        },
        title: { type: String, require: true, row: 1 },
        text: [{ null: { type: "textArea" } }],
    }],
    backgroundImg: {
        type: File,
        require: true
    },
    slots: [{
        duration: { type: Number, require: true },
        price: { type: Number, require: true },
        dates: [{
            startDate: { type: Date, require: true },
            endDate: { type: Date, require: true, gt: 'startDate' },
            deadline: { type: Date, require: true, lt: 'startDate' },
        }]
    }],
    itinerary: [{
        start: { type: Number, require: true },
        end: { type: Number, require: true, gt: 'start' },
        locations: [{
            city: { type: String, require: true },
            places: [{ null: { type: String } }],
        }],
    }],
    activities: [{
        name: { type: String, require: true },
        duration: { type: Number, require: true },
        locations: [{ null: { type: String, require: true } }],
    }],
    priceIncludes: [{
        icon: {
            type: "dropDown",
            options: iconsAdmin,
            require: true,
            row: 1
        },
        data: { type: "textArea", require: true },
    }],
    priceExcludes: [{
        icon: {
            type: "dropDown",
            options: iconsAdmin,
            require: true,
            row: 1
        },
        data: { type: "textArea", require: true },
    }],
    rating: {
        type: "dropDown",
        options: [
            "1",
            "2",
            "3",
            "4",
            "5",
        ],
        require: true
    },
    // reviews: [{
    //     type: String,
    //
    // }],
}

export const transformAgeAndDuration = (values) => {
    values.duration = [];
    if (values.less_than_week)
        values.duration.push("less than week");
    delete values.less_than_week;
    if (values.one_to_two_Weeks)
        values.duration.push("one to two Weeks");
    delete values.one_to_two_Weeks;
    if (values.two_weeks_to_month)
        values.duration.push("two weeks to month");
    delete values.two_weeks_to_month;
    if (values.one_to_two_months)
        values.duration.push("one to two months");
    delete values.one_to_two_months;
    if (values.more_than_two_months)
        values.duration.push("more than two months");
    delete values.more_than_two_months;


    values.age = [];
    if (values.all_ages)
        values.age.push("All");
    delete values.all_ages;
    if (values.nine_to_thirteen)
        values.age.push("nine to thirteen");
    delete values.nine_to_thirteen;
    if (values.fourteen_to_seventeen)
        values.age.push("fourteen to seventeen");
    delete values.fourteen_to_seventeen;
    if (values.eighteen_to_twenty_five)
        values.age.push("eighteen to twenty five");
    delete values.eighteen_to_twenty_five;
    if (values.twenty_six_to_thirty)
        values.age.push("twenty six to thirty");
    delete values.twenty_six_to_thirty;
    if (values.thirty_to_forty)
        values.age.push("thirty to forty");
    delete values.thirty_to_forty;
    if (values.forty_to_forty_five)
        values.age.push("forty to forty five");
    delete values.forty_to_forty_five;
    if (values.forty_six_plus)
        values.age.push("forty six plus");
    delete values.forty_six_plus;

    return values;
}