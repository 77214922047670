import React, { useState, useEffect } from "react";
import classes from "./adminUsers.module.css";
import UserCard from "./userCard/UserCard";
import axios from '../../../requests/axios';
import routes from '../../../requests/routes';
import Loader from '../../../layouts/loader/Loader'

const AdminUsers = () => {
  const [tab, setTab] = useState(0);
  const [data, setData] = useState([]);
  const [loader,setLoader] = useState(false);
  const list = ["News Letter", "Special Requests"];

  async function getNewsLetter() {
    setLoader(true);
    const response = await axios.get(routes.newsLetter);
    setLoader(false);
    setData(response.data.subscribers);
  }

  async function getSpecialRequests() {
    setLoader(true);
    const response = await axios.get(routes.specialRequest);
    setLoader(false);
    setData(response.data.requests);
  }
  useEffect(() => {
    if(tab === 0){
      getNewsLetter()
    }else{ 
      getSpecialRequests()
    }
  }, [tab]);
  return (
    <div style={{ maxWidth: "70vw", margin: 'auto'}}>
      <h1 className="adminTitle">Users</h1>
      <div className={classes.smallScreenSideBar}>
        <ul className={classes.List}>
          {list.map((item, index) => {
            return (
              <li className={classes.item}>
                <p
                  className={
                    tab === index
                      ? `${classes.activeLink}`
                      : `${classes.normalLink}`
                  }
                  onClick={() => setTab(index)}
                >
                  {item}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={classes.cards}>
        {loader ? <Loader color={"#F46444"}/>:
        data.map((user) => (
          <UserCard user={user} tab={tab} setTab={setTab} />
        ))}
      </div>
    </div>
  );
};

export default AdminUsers;
