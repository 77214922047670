import React from "react";
import classes from "./contactus.module.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useState } from "react";
import Email from "../../../../assets/imgs/contactUs/Email.png";
import Phone from "../../../../assets/imgs/contactUs/phone.png";
import Location from "../../../../assets/imgs/contactUs/Location.png";
import Web from "../../../../assets/imgs/contactUs/Web.png";
import HAD from "../../../../assets/imgs/contactUs/HAD.png";
import facebook from "../../../../assets/imgs/contactUs/facebook1.png";
import instagram from "../../../../assets/imgs/contactUs/instagram.png";
import linkedin from "../../../../assets/imgs/contactUs/linkedin.png";
import whats from "../../../../assets/imgs/contactUs/whats.png";
import { NavLink } from "react-router-dom";


function ContactUs(props) {

    function handleModalClose() {
        props.setOpen(false);
    }

    return (
        <Modal open={props.open} onClose={handleModalClose}>
            <Box className={classes.box}>
                <div className={classes.container}>
                    <div className={classes.close}>
                        <button onClick={handleModalClose}>
                            x
                        </button>
                    </div>
                    <h1 className={classes.title}>Contact Us</h1>
                    <div className={classes.info}>
                        <div className={classes.infoData}>
                            <div className={classes.infoItem}>
                                <img src={Location} alt="location" className={classes.img} />
                                <a target="_blank" rel="noreferrer noopener" href="https://goo.gl/maps/mfT5CPHTerhcjLWP7">15 Al Mahrouki. Ard Al Golf, Cairo Egypt.</a>
                            </div>
                            <div className={classes.infoItem}>
                                <img src={Email} alt="email" className={classes.img} />
                                <a href="mailto: Info@have-adream.com">Info@have-adream.com</a>
                            </div>
                            <div className={classes.infoItem}>
                                <img src={Phone} alt="phone" className={classes.img} />
                                <a href="tel:+20 1094414168">+20 01094414168</a>
                            </div>
                            <div className={classes.infoItem2}>
                                <img src={whats} alt="whatsapp" className={classes.img2} />
                                <div className={classes.whatsappNumbers}>
                                    <a target="_blank" rel="noreferrer noopener" href="https://wa.me/201094414168">+20 109 441 4168</a>
                                </div>
                            </div>
                            <div className={classes.infoItem3}>
                                <a target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/haveadream.org">
                                    <img src={facebook} alt="facebook" className={classes.img2} />
                                </a>
                                <a target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/have.adream/">
                                    <img src={instagram} alt="instagram" className={classes.img2} />
                                </a>
                                <a target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/company/have-a-dream-organization/">
                                    <img src={linkedin} alt="linkedin" className={classes.img2} />
                                </a>
                            </div>
                        </div>
                        <div className={classes.picture}>
                            <img src={HAD} alt="HAD" className={classes.imgHad} />
                            <p>We'll answer as Soon as we Can </p>
                        </div>

                    </div>
                </div>
            </Box>
        </Modal>
    );

}

export default ContactUs;