import React, { useEffect, useState } from "react";
import logo from "../../assets/brand/logo_background.png";
import navData from "../../assets/data/navData";
import classes from "./navbar.module.css";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import ListIcon from "@mui/icons-material/List";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { userActions } from "../../store/userSlice";

const NavBar = (props) => {
  const user = useSelector((state) => state.user);
  const [dropDown, setDropDown] = useState(false);
  const [home, setHome] = useState(false);
  const logged = user.loggedIn;
  const location = useLocation();
  //use
  const dispatch = useDispatch();
  //get cuurent path

  useEffect(() => {
    const path = location.pathname;
    if (path === "/")
      setHome(true);
    else
      setHome(false);
  }, [location]);

  function handleLogout() {
    dispatch(userActions.logout());
    setDropDown(false);
  }

  return (
    <nav id="intro" className={home ? classes.navHome : classes.nav}>
      <a href="/">
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logo} alt="logo" />
        </div>
      </a>

      <div className={classes.routes}>
        <ul>
          {navData.Home[0].map((element, index) => {
            return (
              <div className={classes.list}>
                <li className={classes.navItem}>
                  <a
                    href={element.route}
                    activeClassName={classes.activeLink}
                  >
                    <div className={classes.wrapper}>
                      {element.icon}
                      <div>
                        {element.title}{" "}
                        {/* {element.list && (
                          <KeyboardArrowDownIcon className={classes.arrow} />
                        )} */}
                      </div>
                    </div>
                  </a>

                  {/* {element.list && (
                    <ol className={classes.dropDown}>
                      {element.list.map((item, index) => {
                        return (
                          <li className={classes.navSubItem}>
                            <NavLink
                              to={item.route}
                              activeClassName={classes.activeLink}
                            >
                              {item.title}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ol>
                  )} */}
                </li>
              </div>
            );
          })}
          {logged && (
            <>
              {user.isAdmin ?
                <a href={"/admin"}>
                  <li className={classes.navItem}>
                    Admin Center
                  </li>
                </a>
                :
                <>
                  <a href={"/profile/" + user.id + "?tab=Wishlist"}>
                    <li className={classes.navItem}>
                      <FavoriteBorderIcon className={classes.fav} />
                    </li>
                  </a>
                  <a href={"/profile/" + user.id + "?tab=Profile+Info"}>
                    <li className={classes.navItem}>
                      <PersonOutlineIcon className={classes.profile} sx={{ fontSize: "2.4rem" }} />
                    </li>
                  </a>
                </>}
              <NavLink to="/login">
                <li className={classes.navItem} onClick={() => handleLogout()}>
                  <LogoutIcon sx={{ fontSize: "2rem" }} />
                </li>
              </NavLink>
            </>
          )}
          {!logged && (
            <NavLink to="/login">
              <button className={classes.btn}>
                {" "}
                Sign In <TrendingFlatIcon sx={{ fontSize: "2rem" }} />{" "}
              </button>
            </NavLink>
          )}
        </ul>
      </div>

      {/* mobile view */}
      <ListIcon
        className={classes.burger}
        onClick={() => setDropDown(!dropDown)}
      />
      {dropDown && (
        <ol className={classes.dropDown}>
          {navData.Home[0].map((item, index) => {
            return (
              <li
                className={classes.navSubItem}
                onClick={() => setDropDown(false)}
              >
                <NavLink
                  to={item.route}
                  className={classes.navTitle}
                  activeClassName={classes.activeLink}
                >
                  {item.title}
                </NavLink>
              </li>
            );
          })}
          {!logged ? (
            <li
              className={classes.navSubItem}
              onClick={() => setDropDown(false)}
            >
              <NavLink
                to={navData.Home[1][0].route}
                className={classes.navTitle}
                activeClassName={classes.activeLink}
              >
                {navData.Home[1][0].title}
              </NavLink>
            </li>
          ) : user.isAdmin ? (
            navData.Home[3].map((item, index) => {
              return (
                <li
                  className={classes.navSubItem}
                  onClick={
                    item.title === "Log Out"
                      ? () => handleLogout()
                      : () => {
                        setDropDown(false);
                      }
                  }
                >
                  <NavLink
                    to={
                      item.title === "Profile"
                        ? "/profile/" + user.id
                        : item.route
                    }
                    className={classes.navTitle}
                    activeClassName={classes.activeLink}
                  >
                    {item.title}
                  </NavLink>
                </li>
              );
            })
          ) : (
            navData.Home[2].map((item, index) => {
              return (
                <li
                  className={classes.navSubItem}
                  onClick={
                    item.title === "Log Out"
                      ? () => handleLogout()
                      : () => {
                        setDropDown(false);
                      }
                  }
                >
                  <NavLink
                    to={
                      item.title === "Profile"
                        ? "/profile/" + user.id
                        : item.route
                    }
                    className={classes.navTitle}
                    activeClassName={classes.activeLink}
                  >
                    {item.title}
                  </NavLink>
                </li>
              );
            })
          )}
        </ol>
      )}
    </nav>
  );
};

export default NavBar;
