import React, { useState , useEffect} from "react";
import classes from "./policy.module.css";
import { useNavigate } from "react-router-dom";

function Policy()
{

return(

<div className={classes.container}>
    <h1 className={classes.heading}>Privacy Policy</h1>
    <p className={classes.para}>Effective date: January: 7th, 2023</p>
    <p className={classes.para}>This Privacy Policy describes our policies and procedures regarding the collection, use, and disclosure of your information when you use the Service. It also informs you about your privacy rights.</p>
    <p className={classes.para}>We use your personal data retrieved from Google to provide and improve the Service. We don't collect any additional data from you.</p>
    <p className={classes.para}>To offer you the best experience, we only use your full name, email, profile picture, and Google ID when you sign in with Google.</p> 

</div>

);
};
export default Policy;