import React from "react";
import classes from "./form.module.css";
import { Formik, Form } from "formik";
import CreateYupSchema from "../../utils/genericYup"
import { renderFields } from "./formUtils";
import AddableForm from "./addableForm/addableForm";


function FormikForm(props){
    const { model, initialValues, onSubmit, submitMsg, orderByRows } = props;

    const validationSchema = CreateYupSchema(model);

    const modelFields = Object.fromEntries(
        Object.entries(model).filter(([_,v]) => !Array.isArray(v))
    )
    const modelArrayFields = Object
        .entries(model).filter(([_,v]) => Array.isArray(v))

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnMount={true}
            onSubmit={(values, {...others}) => onSubmit(
                Object.keys(values)
                    .filter((key) => !key.includes("["))
                    .reduce((obj, key) => {
                        return Object.assign(obj, {
                          [key]: values[key]
                        });
                  }, {})
                , {...others})}
            >
            {({ values, isSubmitting, isValid, setValues, setFieldValue}) => (
                <Form>
                    <div className={classes.boxContainer}>
                        {renderFields(modelFields, orderByRows, setValues)}
                        {modelArrayFields.map(([key, value]) => {
                            return (
                                <div key={key} className={classes.arrayFields}>
                                    <h2>{key}</h2>
                                <AddableForm key={key}
                                    model={value[0]}
                                    name={key}
                                    orderByRows={orderByRows}
                                    values={values}
                                    setValues={setValues}
                                    setFieldValue={setFieldValue}
                                />
                                </div>
                            )
                        })}
                    </div>

                    <div className={classes.btn}>
                        <button type="submit" className={classes.button} style={isValid ? {}: {opacity: ".5"}}>
                            <div className={classes.submitLoader} disabled={!isSubmitting}></div>
                            {submitMsg}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )

}

export default FormikForm;
