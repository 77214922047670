


const navData = {
    "Home":
    [
        [
            {
                "title":"About",
                "route":"/about",
            },


            {
                "title":"Trips",
                "route":"/programs",
            },

        ],
        [
            
            {
                "title":"Sign In",
                "route":"/login",
            },

        ],
        [
            {
                "title":"Profile",
                "route":"/profile/"+ localStorage.getItem("id"),
            },
            {
                "title":"Log Out",
                "route":"/login",
            },
        ],
        [
            {
                "title":"Log Out",
                "route":"/login",
            },
            {
                "title":"Admin Center",
                "route":"/admin",
            },
        ]
    ],
}

export default navData