import classes from "./CarouselHeroSection.module.css";

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function CarouselHeroSection(props) {


    function displayCarouselItems(images) {
        return images.map((item, index) =>
            <img key={index} src={item} className={classes.carouselImage} alt="homeImage" />
        )
    }
    function renderDotsItem(e) {
        return <div className={`${e.isActive ? classes.active : ""} ${classes.carouselIndexBtn}`}></div>
    }

    function renderPrevButton(e) {
        return <ArrowBackIosNewIcon className={`${e.isDisabled ? classes.disabled : ""} : ${classes.carouselBtn} ${classes.leftBtn}`} />
    }
    function renderNextButton(e) {
        return <ArrowForwardIosIcon className={`${e.isDisabled ? classes.disabled : ""} : ${classes.carouselBtn} ${classes.rightBtn} `} />
    }
    return (
        <section className={classes.hero} style={{ "--carousel-tint": props.tintColor }}>
            <AliceCarousel
                autoPlay
                autoPlayInterval={3000}
                animationType="fadeout"
                infinite
                mouseTracking
                items={displayCarouselItems(props.images)}
                renderDotsItem={renderDotsItem}
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
            />
            <div className={classes.heroContent}>
                {props.title && <h1 className={classes.heroTitle}> {props.title}</h1>}
                {props.btn && <a href="programs" className={classes.heroBtn}>{props.btn}</a>}
            </div>
        </section>
    );
}
