import React, { useEffect, useState, useRef } from "react";
import programs from "../../assets/data/programsData";
import EventCard from "../../generic components/event card/EventCard";
import axios from "../../requests/axios";
import routes from "../../requests/routes";
import { NavLink } from "react-router-dom";
import FilterButton from "./filterButton/FilterButton";
import GetStarted from "./getStarted/GetStarted";
import noPrograms from "../../assets/imgs/notFound/programs.png";
import classes from "./programs.module.css";
import countriesList from "../../assets/data/countryFlags";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import Loader from "../../layouts/loader/Loader";
import toast from "react-hot-toast";
import MyToaster from "../../generic components/toaster/MyToaster.js";

import * as Yup from "yup";
import exchangeImg1 from "../../assets/imgs/icons/programs/exchange.png";

import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

import Slider from "@mui/material/Slider";
import { Field, Form, Formik, ErrorMessage } from "formik";
import tripsTypes from "../../assets/data/tripsTypes.js";
import Overlay from "../../generic components/overlay/Overlay.tsx";

const destinations = [
  "Africa",
  "Asia",
  "Australia",
  "Europe",
  "North America",
  "South America",
];

const types = [
  "Group",
  "Family",
  "Couples",
  "Solo",
  "Honeymoon",
  "Business",
  "Other",
];

const ageGroups = {
  "All": "All",
  "9 - 13": "nine to thirteen",
  "14 - 17": "fourteen to seventeen",
  "18 - 25": "eighteen to twenty five",
  "26 - 30": "twenty six to thirty",
  "30 - 40": "thirty to forty",
  "40 - 45": "forty to forty five",
  "46+": "forty six plus",
};

const ProgramsPage = () => {
  const [program, setProgram] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [programData, setProgramData] = useState([]);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [title, setTitle] = useState("All Trips");
  const [selected, setSelected] = useState(Array(programs.length).fill(false));
  const [filterOpen, setFilterOpen] = useState(false);
  const [specialRequestsOpen, setSpecialRequestsOpen] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 3000]);
  const submitRef = useRef();
  const [countries, setCountries] = useState(["All"]);

  const initialValues = {
    country: countries[0],
    startDate: "",
    endDate: "",
    duration: "",
    age: "",
    gender: "",
    minPrice: 0,
    maxPrice: 500,
  };

  const handleSubmit = async (values) => {
    values.minPrice = priceRange[0];
    values.maxPrice = priceRange[1];
    setFilterOpen(false);
    setIsLoading(true);


    let filterStr = "?currency=USD";
    if (values.minPrice) {
      filterStr += `&minPrice=${values.minPrice}`;
    }
    if (values.maxPrice) {
      filterStr += `&maxPrice=${values.maxPrice}`;
    }
    if (values.country && values.country !== "All") {
      filterStr += `&location=${values.country}`;
    }
    if (values.startDate) {
      let filterStartDate = new Date(values.startDate).toISOString();
      filterStr += `&startDate=${filterStartDate}`;

      if (!values.endDate) {
        let filterEndDate = new Date(values.startDate);
        filterEndDate.setDate(filterEndDate.getDate() + 10 * 365);
        filterEndDate = filterEndDate.toISOString();
        filterStr += `&endDate=${filterEndDate}`;
      }
    }

    if (values.endDate) {
      let filterEndDate = new Date(values.endDate).toISOString();
      filterStr += `&endDate=${filterEndDate}`;
    }

    if (values.age && values.age !== "All") {
      filterStr += `&age=` + values.age;
    }

    if (values.gender && values.gender !== "any") {
      filterStr += `&gender=${values.gender}`
    }

    if (values.duration && values.duration !== "any") {
      filterStr += `&duration=${values.duration}`
    }
    var programIndex = tripsTypes.findIndex((type) => type.name === title);
    if (programIndex === -1) programIndex = "";

    filterStr += `&type=${programIndex}`;

    console.log(values, "values");
    const response = await axios.get(
      encodeURI(routes.getPrograms + filterStr)
    );

    setFilteredPrograms(response.data.programs);
    setIsLoading(false);
  };
  function getPrograms() {
    // var programIndex = tripsTypes.findIndex((type) => type.name === title);
    // if (programIndex === -1) programIndex = "";
    // try {
    //   setIsLoading(true);
    //   const response = await axios.get(
    //     encodeURI(routes.getPrograms + "?type=" + programIndex)
    //   );
    //   setProgramData(response.data.programs);
    //   setFilteredPrograms(response.data.programs);
    //   setIsLoading(false);
    // } catch (err) {
    //   setIsLoading(false);
    // }
    submitRef.current.click();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getPrograms();
  }, [program]);

  useEffect(() => {
    const countryNames = countriesList.map((item) => item.country);
    setCountries(["All", ...countryNames]);
  }, []);

  function handleClick(program, index) {
    setSelected(
      Array(programs.length)
        .fill(false)
        .map((val, i) => {
          return index === i;
        })
    );
    setTitle(program);
  }

  const initialValuesSpecialRequests = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    destination: destinations[0],
    type: types[0],
    description: "",
  };

  async function handleSpecialSubmit(data, { resetForm }) {
    async function sendRequest() {
      try {
        await axios.post(routes.specialRequest, data);
        resetForm(initialValues);
      } catch (err) {
        console.log(err);
      }
      setSpecialRequestsOpen(false);
    }
    toast.promise(sendRequest(), {
      loading: "Loading",
      success: "Your request has been sent!",
      error: "Error occurred",
    });
  }

  return (
    <div className={classes.container}>
      <h1 className={classes.pageHeader}>TRIPS</h1>
      {/* <NavLink to="/">
        <ArrowCircleLeftIcon className={classes.backButton} />
      </NavLink> */}
      <div className={classes.buttons}>
        {programs.map((program, index) => (
          <div onClick={() => handleClick(program.title, index)}>
            <FilterButton
              selected={selected[index]}
              key={index}
              title={program.title}
              icon={program.icon}
              onSelect={setProgram}
            />
          </div>
        ))}
        <div onClick={() => setSpecialRequestsOpen(true)}>
          <FilterButton
            selected={selected[programs.length]}
            // { title: "Special Requests",  icon: exchangeImg1, }
            title="Special Requests"
            icon={exchangeImg1}
            onSelect={setProgram}
          />
        </div>
      </div>
      <h2 className={classes.sectionTitle}>
        <span>{title}</span>
        <button
          style={{
            height: "4rem",
          }}
          onClick={() => setFilterOpen(true)}
        >
          <FilterListOutlinedIcon sx={{ color: "var(--orange)" }} /> FILTER
        </button>
      </h2>

      {isLoading ? (
        <div className={classes.loadingBar}>
          <Loader color={"#F46444"} />
        </div>
      ) : filteredPrograms?.length === 0 ? (
        <div className={classes.emptyPrograms}>
          <img src={noPrograms} alt="No Programs" />
          <p>No Programs Available</p>
        </div>
      ) : (
        <div
          className={classes.eventList}
          loading={isLoading || filteredPrograms.length <= 0 ? "true" : "false"}
        >
          {isLoading ? (
            <Loader color={"#F46444"} />
          ) : (
            filteredPrograms?.map((card) => (
              <EventCard
                id={card._id}
                key={card._id}
                img={card.image}
                title={card.name}
                date={card.startDate}
                duration={card.duration}
                location={card.location}
                isRecurrent={card.isRecurrent}
                price={card.price} //Price attribute is not provided by backend response
                currency={card.currency}
                load={false}
                type={tripsTypes[card.type]?.name}
                review={false}
                wishlist={false}
              />
            ))
          )}
        </div>
      )}
      <div className={classes.getStarted}>
        <GetStarted />
      </div>
      <Overlay
        header="For special requests"
        open={specialRequestsOpen}
        onClose={() => setSpecialRequestsOpen(false)}
        position="right"
        width="50rem"
      >
        <Formik
          initialValues={initialValuesSpecialRequests}
          onSubmit={handleSpecialSubmit}
        >
          {({ values }) => (
            <Form className={classes.form}>
              <div className={classes.boxContainer}>
                <div className={classes.nameFields}>
                  <div className={classes.name}>
                    <label className={classes.label}>
                      First Name <span>*</span>
                    </label>
                    <Field
                      className={classes.field}
                      name="firstName"
                      type="text"
                      autoComplete="off"
                    />
                    <ErrorMessage name="firstName" component="span" />
                  </div>
                  <div className={classes.name}>
                    <label className={classes.label}>Last Name</label>
                    <Field
                      className={classes.field}
                      name="lastName"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className={classes.nameFields}>
                  <div className={classes.name}>
                    <label className={classes.label}>
                      Email <span>*</span>
                    </label>
                    <Field
                      className={classes.field}
                      name="email"
                      type="email"
                      autoComplete="off"
                    />
                    <ErrorMessage name="email" component="span" />
                  </div>
                  <div className={classes.name}>
                    <label className={classes.label}>
                      Phone Number <span>*</span>
                    </label>
                    <Field
                      className={classes.field}
                      name="phoneNo"
                      type="text"
                      autoComplete="off"
                    />
                    <ErrorMessage name="phoneNo" component="span" />
                  </div>
                </div>
                <div>
                  <label className={classes.label}>
                    Select your Preferred Destination <span>*</span>
                  </label>
                  <Field
                    as="select"
                    name="destination"
                    className={classes.field}
                  >
                    {destinations.map((destination) => {
                      return <option value={destination}>{destination}</option>;
                    })}
                  </Field>
                </div>

                <div>
                  <label className={classes.label}>
                    Type of your Trip <span>*</span>
                  </label>
                  <Field as="select" name="type" className={classes.field}>
                    {types.map((type) => {
                      return (
                        <option
                          value={type}
                        // className="dropItem"
                        >
                          {type}
                        </option>
                      );
                    })}
                  </Field>
                </div>

                <div>
                  <label className={classes.label}>
                    Description of requested Trip
                  </label>
                  <Field
                    as="textarea"
                    name="description"
                    placeholder="Description..."
                    className={classes.field}
                  />
                </div>

                <p>
                  For information on how to unsubscribe, as well as our privacy
                  practices and commitment to protecting your privacy, please
                  review our Privacy Policy.
                </p>
              </div>

              <div className={classes.btn}>
                <button type="submit" className="button">
                  Send
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Overlay>
      <Overlay
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        header="Filter"
        width="50rem"
      >
        <Formik initialValues={initialValues} onSubmit={handleSubmit} >
          {({ values }) => (
            <Form className={classes.form} >
              <div className={classes.boxContainer}>
                <div className={classes.destination}>
                  <label className={classes.label}>
                    Select your Preferred Destination
                  </label>
                  <Field as="select" name="country" className={classes.field}>
                    {countries.map((country) => {
                      return <option value={country}>{country}</option>;
                    })}
                  </Field>
                </div>

                <div className={classes.name}>
                  <label className={classes.label}>Travel Length</label>
                  <Field
                    as="select"
                    className={classes.field}
                    name="duration"
                    type="text"
                    autoComplete="off"
                  >
                    <option value="any">
                      Any
                    </option>
                    <option value="less than week">{"< week"}</option>
                    <option value="one to two Weeks">{"1 - 2 weeks"}</option>
                    <option value="two weeks to month">{"2 weeks - 1 month"}</option>
                    <option value="one to two months">{"1 - 2 months"}</option>
                    <option value="more than two months">{"> 2 months"}</option>
                  </Field>
                </div>
                <div className={classes.nameFields}>
                  <div className={classes.name}>
                    <label className={classes.label}>Start Date</label>
                    <Field
                      className={classes.field}
                      name="startDate"
                      type="date"
                      autoComplete="off"
                    />
                  </div>
                  <div className={classes.name}>
                    <label className={classes.label}>End Date</label>
                    <Field
                      className={classes.field}
                      name="endDate"
                      type="date"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className={classes.destination}>
                  <label className={classes.label}>Age</label>
                  <Field as="select" name="age" className={classes.field}>
                    {Object.entries(ageGroups).map(([key, value]) => {
                      return <option value={value}>{key}</option>;
                    })}
                  </Field>
                </div>
                <div className={classes.destination}>
                  <label className={classes.label}>Gender</label>

                  <Field as="select" name="gender" className={classes.field}>
                    <option value="any">Any</option>;
                    <option value="male">Male</option>;
                    <option value="female">Female</option>;
                  </Field>
                </div>
                <label className={classes.label}>Price Range</label>
                <Slider
                  value={priceRange}
                  min={0}
                  max={3000}
                  onChange={(event, newValue) => setPriceRange(newValue)}
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) => `$ ${value}`}
                  disableSwap
                  className={classes.slider}
                />
              </div>

              <div className={classes.btn}>
                <button type="submit" className={classes.button} ref={submitRef}>
                  Apply
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Overlay>
    </div>
  );
};

export default ProgramsPage;
