import React, { useState , useEffect} from "react";
import classes from "./faqs.module.css";
import { useNavigate } from "react-router-dom";
import ExpandableSection from "../programDetails/expandableSection/ExpandableSection.js";
import faqsData from "../../assets/data/faqsData.js";
function Faqs()
// 
// By accessing and using this website, you agree to comply with the following Terms & Conditions, which together with our Privacy Policy govern Have A Dream’s relationship with you concerning this website. If you disagree with any part of these terms, please do not use our website or services.

{
return(

<div className={classes.container}>
    <h1 className={classes.pageHeader}>FAQs</h1>
    <section className={classes.detailsSection}>
        {faqsData.terms.map((term) => (
            <ExpandableSection title={term.title} aux={true} {...term} />
        ))}
    </section>
</div>

);
};
export default Faqs;