// {title: "Volunteering",icon: volunteeringImg1, },
// { title: "Tourism Programs",  icon: tourismImg1, },
// { title: "African Ambassadors",  icon: africanImg1, }, 
// { title: "Have a Dream Juniors",  icon: juniorsImg1, },
// { title: "Erasmus+",  icon: erasmusImg1, },
// { title: "Cultural Exchange",  icon: exchangeImg1, }]


const tripsTypes = [
    {
        id: 0,
        name: 'Volunteering'
    },
    {
        id: 1,
        name: 'Adventurous & Road trips'
    },
    {
        id: 2,
        name: 'Have a Dream Juniors'
    },
    {
        id: 3,
        name: 'EU program'
    },
    {
        id: 4,
        name: 'Special Requests'
    }
];

export default tripsTypes;