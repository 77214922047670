
const faqs =
{
    "terms": [
        {
            "id": 1,
            "icon": 1,
            "title": "What programs do you offer?",
            "text": [ "Have a Dream is offering unique travel experiences through various programs.", "Individual Volunteering Programs:", "• Our Fly to Connect individual volunteering program in various countries offers various activities such as teaching, farming, cultural exchange, and animal care. This program is available for individuals seeking diverse aspects of travel and allows them to choose their preferred time and duration.", "Erasmus+ ESC:", "• A youth exchange and volunteering program funded by the European Union (EU).", "Road Trips:", "• Since 2017, we have been organizing road trips and adventures across Asia, Africa, and Europe.", "Visa Service:","• Including services for the USA, UK, and Canada." ], 
        },
        {
            "id": 2,
            "icon": 1,
            "title": "Do you have scholarships?",
            "text": ["Unfortunatley, we don't offer any scholarships at the moment."], 
        },
        {
            "id": 3,
            "icon": 1,
            "title": "Can my 15-year-old son join your programs?",
            "text": ["If you are under the age of 18, you can either join one of our summer camps offered for individuals from the age of 11 to 17, or join Fly To Connect with a parent or a guardian, or within a group volunteering program."], 
        },
        {
            "id": 4,
            "icon": 1,
            "title": "What is the required English level?",
            "text": ["• If you are applying for one of our teaching programs, ESC, and Erasmus+ Projects, your English level must be at least B2.", "• If you are applying for any other type of programs, you are expected to be able to communicate clearly in English."], 
        },
        {
            "id": 5,
            "icon": 1,
            "title": "Do you offer Visa Support in Volunteering Programs?",
            "text": ["Yes, Have A Dream offers visa support for both egyptians and non-egyptians in terms of:", "• Invitation Letters.", "• Assisting in Visa Applications.", "• Reviewing the needed documents."], 
        },
        {
            "id": 6,
            "icon": 1,
            "title": "Is the accommodation safe?",
            "text": ["Absolutely! Volunteer safety is our top priority. We carefully select accommodations that are secure, regularly monitored, and managed by our trusted hosting partners. Rooms are separated by gender and shared with other volunteers, ensuring a comfortable and clean environment. Accommodation costs are included in the program fees."],
        },
        {
            "id": 7,
            "icon": 1,
            "title": "When should I receive a response for my application?",
            "text": ["Each program follows its own timeline:", "• Fly to Connect Programs: You can expect a response within 1 week of applying.", "• Erasmus+ Co-funded Programs: You can expect a response within 1 month of applying."], 
        },
        {
            "id": 8,
            "icon": 1,
            "title": "What's the refund policy?",
            "text": ["• No Refund for cancellations without visa rejection.", "• No Refund for emergency cancellations.", "• Refunds in other cases will vary depending on the program, and the specific amount will be clarified in the trip details.", "• Policies may differ for each program and will be outlined in the agreement provided."], 
        },
        {
            "id": 9,
            "icon": 1,
            "title": "What is FTC?",
            "text": ["Fly to Connect is a self-funded, year-round individual  international volunteering program. You have the flexibility to choose the ideal timing and duration for your experience! Available destinations in 3 continents."], 
        },
        {
            "id": 10,
            "icon": 1,
            "title": "How to apply in the volunteering programs?",
            "text": ["1. Check & read the program details carefully on our website.", "2. Fill out the application.", "3. Attend the online interview.", "4. Pay once accepted."], 
        },
        {
            "id": 11,
            "icon": 1,
            "title": "What is the requirements for joining FTC programs?",
            "text": ["To ensure a positive and meaningful experience for you and the communities we serve, certain essential requirements must be met:", "• Age: You should be at least 18 years old, or if you are less than 18 you can join Fly To Connect with a parent or a guardian, or within a group volunteering program.", "• Physical and Mental Health: Good physical and mental health is necessary to participate in program activities. Please disclose any pre-existing medical conditions or limitations.", "• Motivation and Commitment: A strong desire to make a positive impact and contribute to the community is essential. Be prepared to actively engage in program activities and embrace new experiences.", "• Flexibility and Adaptability: An open and flexible attitude will help you handle changing circumstances and unexpected challenges, maximizing your experience."],
        },
        {
            "id": 12,
            "icon": 1,
            "title": "What is the age limit for FTC programs?",
            "text": ["Our programs are designed to accommodate individuals aged 18 to 40 to have a diverse and dynamic group of volunteers, and ensure a vibrant and enriching experience for all participants.", "However, it’s important to note that there may be exceptions to this age range for certain programs. Some programs may have specific requirements or limitations based on the nature of the project or the community we are serving."], 
        },
        {
            "id": 13,
            "icon": 1,
            "title": "What do the program fees include?",
            "text": ["The program fees cover the following:", "• Accommodation", "• Meals", "• Airport transportation (pick-up/drop-off)", "• Support & Follow-up throughout the program", "• Pre-departure Training", "Please note that inclusions may vary depending on the specific program."], 
        },
        {
            "id": 14,
            "icon": 1,
            "title": "Can I apply if I am not Egyptian?",
            "text": ["Yes, our programs is available for all nationalities! Non-Egyptians can apply for our volunteering programs, given that you have the nationality of the country you live in or a valid residence permit.", "However, It might be difficult though for a few nationalities to join some of our programs due to the visa process. If you have doubts, kindly get back to us for advice before you apply."],
        },
        {
            "id": 15,
            "icon": 1,
            "title": "Is there any hidden fees?",
            "text": ["No, but always put into consideration you may need extra money for activities that are not covered in the programs, snacks or even emergency, so make sure to carefully read the inclusions & exclusions for each program."], 
        },
        {
            "id": 16,
            "icon": 1,
            "title": "What are the payments methods? Do you provide intsallements plan?",
            "text": ["Payment Methods:", "• You can pay in Cash (at our office), Debit or Credit Card.", "• Using your Credit Card or ValU, you can pay the 10,000 EGP (downpayment) or the flight tickets in installements. Interest rates vary based on your bank, installment plan, or ValU subscription. Additional details are available upon request."], 
        },
        {
            "id": 17,
            "icon": 1,
            "title": "Can a group of family and friends volunteer together?",
            "text": ["Yes, absolutely! friends & families can volunteer together. Just decide the duration of stay and apply to the program. However, However, You should be aware that you will be accommodated in gender seperated rooms."],
        },
        {
            "id": 18,
            "icon": 1,
            "title": "Should I have a professional experience to apply for these programs?",
            "text": ["No, our volunteering programs doesn't require previous professional experience. You should only:", "• Meet the volunteering requirements.", "• Have a real interest in the program.", "• Have a genuine desire and motivation to make a positive impact and contribute to the community,", "• Be prepared to actively participate in the activities.", "• Felxibility"], 
        },
        {
            "id": 19,
            "icon": 1,
            "title": "What is the difference between Erasmus+ Co-funded projects & ESC Projects?",
            "text": ["Erasmus+:", "Offers short-term projects that range from a few days to a few weeks. These include Youth Exchange programs for individuals aged 18 to 30, as well as training programs that have no age limit.", "European Solidarity Corps (ESC):", "Offers long-term projects, typically lasting from 2 months to 12 months, and is open to individuals aged 18 to 30."], 
        },
        {
            "id": 20,
            "icon": 1,
            "title": "What are the available opportunities for the Erasmus+ Co-funded Projects?",
            "text": ["We regularly post updates on available co-funded programs and opportunities on our website and social media channels. Be sure to stay tuned and keep an eye out for announcements!"], 
        },
        {
            "id": 21,
            "icon": 1,
            "title": "What are the requirements for the Erasmus+ Co-funded Projects?",
            "text": ["Generally, the volunteer should:", "• Have a real interest in the project.", "• Have a valid Passport.", "• Have a good English Level.", "• Be within the program's age range.", "• Present his CV and other personal dicuments.", "Note that the requirements could change from one project to another."], 
        },
        {
            "id": 22,
            "icon": 1,
            "title": "When Do I receive the re-fund for Erasmus+ Co-funded Projects?",
            "text": ["It depends on the hosting organization, but typically within 3 months."], 
        },
        {
            "id": 23,
            "icon": 1,
            "title": "What is the total program cost for Erasmu+ programs?",
            "text": ["You’ll need to cover visa costs and any personal activities outside the program. Additionally, you’ll be responsible for paying for your flight tickets upfront, which will be reimbursed later.", "Please note:", "• Flight ticket costs are reimbursed up to a specified limit.", "• Some programs may cover visa expenses."],
        },
        {
            "id": 24,
            "icon": 1,
            "title": "How to apply for a co-funded Erasmus+ Projects?",
            "text": ["Make sure to follow us on social media, and once you find a suitable program for you, you can apply as follows:", "• Review all program details.", "• If you believe you’re a good fit, complete the application form in English, ensuring all fields are filled in.", "• After the application deadline, Have A Dream will review all submissions, if your application is successful, you’ll be invited for an interview.", "• After the interview, wait for the acceptance email."], 
        },
        {
            "id": 25,
            "icon": 1,
            "title": "Could you help me with the ESC application?",
            "text": ["For the ESC program, we offer support as your sending organization. Be sure to request our E-book, which provides valuable guidance to assist you throughout the application process."], 
        },
        {
            "id": 26,
            "icon": 1,
            "title": "Can you be my sending organization?",
            "text": ["If you’re accepted into a project and would like us to be your sending organization, feel free to contact us. We’ll arrange an online interview, and if approved, we’ll officially serve as your sending organization."], 
        },
        {
            "id": 27,
            "icon": 1,
            "title": "What's the number of travelers per group?",
            "text": ["Each trip has a limited number of spots, usually between 10 and 20, to create a more intimate and personalized experience. This smaller group size helps participants connect and get to know each other better."], 
        },
        {
            "id": 28,
            "icon": 1,
            "title": "If I am applying for Shcengen Visa, How much money should I have in my bank account?",
            "text": ["It is recommended to have 90 - 120 Euros that covers each of your travel days."], 
        },
        {
            "id": 29,
            "icon": 1,
            "title": "What does the package fees includes?",
            "text": ["Our Package Fees covers the following:", "• Accomodations", "• Breakfast", "• Program's Activities & Entrance Fees.", "• Domestic Transportations.", "• Team Leader from Have A Dream.", "• Visa Guidance & Support.", "Note: It could vary from one program to another so  make sure to review the program's details"],
        },
    ],
}

export default faqs;