import React from "react";
import classes from "./userCard.module.css";
import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";

const UserCard = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.userInfo}>
        <h3>
          {props.user.firstName} {props.user.lastName}
        </h3>
        <p>
          <CiMail />
          {props.user.email}
        </p>
        <p>
          <CiPhone />
          {props.user.phoneNo}
        </p>
      </div>
      {props.user.destination ? (
        <div className={classes.request}>
          <div>
            <h4>
              <CiLocationOn />
              {props.user.destination}
            </h4>
            <h3>{props.user.type}</h3>
          </div>
          <p>{props.user.description}</p>
        </div>
      ) : null}
    </div>
  );
};

export default UserCard;
