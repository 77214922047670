const TermsConditions =
{
    "terms": [
        {
            "id": 1,
            "icon": 1,
            "title": "General",
            "text": ["These Terms & Conditions apply to all users of the website and customers of Have A Dream. By using our services, you agree to the terms set forth below. These terms may be updated from time to time, and we encourage you to review this page periodically."]
        },
        {
            "id": 2,
            "icon": 1,
            "title": "Services Provided",
            "text": ["Have A Dream offers tailored experiences in travel, cultural exchange, and international volunteering. We provide customized trips, volunteer opportunities, and immersive camps both in Egypt and abroad. All services are subject to availability and require booking confirmation."]
        },
        {
            "id": 3,
            "icon": 1,
            "title": "Booking & Payment",
            "text": ["Booking Confirmation: A booking is confirmed only upon receiving a written confirmation from Have A Dream. The company reserves the right to cancel or modify bookings in the event of unforeseen circumstances."," Payments: Payment terms, including whether full or partial payment is required, depend on the specific program or service booked. Payment deadlines are outlined in your invoice or package agreement. Failure to make payments on time may result in cancellation of your booking, potentially without a refund, depending on the program's terms.","Cancellations: Cancellations must be submitted in writing. Please note that cancellation policies, including any applicable charges, vary by program and service. Specific terms and conditions are outlined in the booking confirmation for each individual service. Refunds: Refund eligibility and conditions are determined by the specific program and booking terms. For detailed information on refund policies, please refer to your booking confirmation or contact customer service."]
        },
        {
            "id": 4,
            "icon": 1,
            "title": "Travel Requirements & Responsibility",
            "text": ["Visas and Passports: It is the responsibility of the customer to ensure they have the necessary documentation (passport, visas, vaccinations) for the travel destinations. Have A Dream is not liable for any issues related to insufficient or incorrect travel documentation.", "Health and Safety: Customers are responsible for ensuring they are medically fit to travel and participate in volunteer programs. Have A Dream recommends that travelers seek medical advice before embarking on any trips, especially for international travel.", "Code of Conduct: While participating in our programs, customers are expected to behave respectfully towards local communities, environments, and fellow travelers. Have A Dream reserves the right to terminate services without refund if this code is violated."]
        },
        {
            "id": 5,
            "icon": 1,
            "title": "Limitation of Liability",
            "text": ["Have A Dream acts as an intermediary between customers and suppliers (such as airlines, hotels, and volunteer organizations). We are not liable for any injury, loss, delay, or damage that may occur due to circumstances beyond our control, including but not limited to acts of God, war, political unrest, or natural disasters."]
        },
        {
            "id": 6,
            "icon": 1,
            "title": "Intellectual Property",
            "text": ["All content on this website, including text, graphics, logos, and images, is the property of Have A Dream and is protected by copyright and trademark laws. Unauthorized use of any content may result in legal action."]
        },
        {
            "id": 7,
            "icon": 1,
            "title": "Privacy",
            "text": ["Your use of this website is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal data. Please refer to our Privacy Policy for more information."]
        },
        {
            "id": 8,
            "icon": 1,
            "title": "Governing Law",
            "text": ["These Terms & Conditions are governed by and construed by the laws of Egypt. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the Egyptian courts."]
        },
        {
            "id": 9,
            "icon": 1,
            "title": "Amendments",
            "text": ["Have A Dream reserves the right to update or modify these Terms & Conditions without prior notice. Continued use of the website or services after any changes will constitute acceptance of those changes."]
        },
        {
            "id": 10,
            "icon": 1,
            "title": "Contact Us",
            "text": ["If you have any questions or concerns regarding these Terms & Conditions, please contact us at: ","Email: info@have-adream.com","Phone: 01094414168","Address: 15 Al Mahrouki, Al Golf, Cairo."]
        }
    ],
}

export default TermsConditions;