import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import avatarImg from "../../assets/imgs/avatar.png";
import noFeed from "../../assets/imgs/notFound/noFeed.png";
import noWishlist from "../../assets/imgs/notFound/noWishlist.png";
import axios from "../../requests/axios";
import routes from "../../requests/routes";
import MyFeed from "./My Feed/MyFeed";
import MyWishlist from "./My Wishlist/MyWishlist";
import PersonalInfo from "./Personal Information/PersonalInfo";
import classes from "./profile.module.css";
import ProfileBar from "./ProfileBar";
import { useSearchParams } from "react-router-dom";


const Profile = () => {
  const myUser = useSelector((state) => state.user);
  const id = myUser.id;
  const [user, setUser] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  async function getUser() {
    try {
      const response = await axios.get(routes.getUser + id);
      setUser(response.data);
    } catch (err) { }
  }
  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className={classes.container}>
      <section className={classes.profile}>
        <div className={classes.profileInfo}>
          <div className={classes.profileImage}>
            {user.image ? (
              <img src={user.image} alt="" />
            ) : (
              <img src={avatarImg} alt="" />
            )}
          </div>
          <div className={classes.profileInfo}>
            <div className={classes.profileDetails}>
              <h1 className={classes.profileName}>{user.fullName}</h1>
              <div className={classes.extraDetails}>
                <h1 className={classes.profileTitle}>{user.type}</h1>
                <h1 className={classes.profileTitle}>+{user.points}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <ProfileBar />
      </section>
      {/* {searchParams.get("tab") === "My Feed" ? (
        <>
          {user.programs === 0 && user.blogs === 0 && user.gallery === 0 ? (
            <div className={classes.emptyFeed}>
              <div className={classes.noFeed}>
                <img src={noFeed} alt="" />
                <div className={classes.letsFill}>
                  LET'S FILL YOUR PROFILE WITH ADVENTURES!
                </div>
              </div>
            </div>
          ) : (
            <section>
              <MyFeed user={user} />
            </section>
          )}
        </>
      ) : null} */}
      {searchParams.get("tab") === "Wishlist" ? (
        <>
          {user.wishlist === 0 ? (
            <div className={classes.emptyFeed}>
              <div className={classes.noFeed}>
                <img src={noWishlist} alt="" />
                <div className={classes.letsFill}>NO LIKES YET</div>
              </div>
            </div>
          ) : (
            <section>
              <MyWishlist user={user} wishlist={true} />
            </section>
          )}
        </>
      ) : null}
      {searchParams.get("tab") === "Profile Info" ? (
        <section>
          <PersonalInfo
            user={user}
            id={user._id}

          />
        </section>
      ) : null}
    </div>
  );
};

export default Profile;
