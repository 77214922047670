import classes from './itinerary.module.css';
import ItineraryCard from './iteneraryCard/itineraryCard';

function Itinerary(props){
    const {itinerary} = props;

    return (
        <div className={classes.container}>
            {itinerary.map((pin) => {
                return (
                    <ItineraryCard pin={pin} />
                )
            })}
            
        </div>
    );
}

export default Itinerary;