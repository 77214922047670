import React, { useEffect, useState } from 'react';

// @ts-ignore
import classes from './overlay.module.css';
import CloseIcon from '@mui/icons-material/Close';

interface OverlayProps {
    children?: React.ReactNode;
    header?: React.ReactNode | string;
    open: boolean;
    onClose?: () => void;
    position?: 'right' | 'left';
    width?: string;
}


const overlayHeader = (headerText) =>
(
    <div className={classes.overlayHeader}>
        <h1>{headerText}</h1>
        <hr />
    </div>
)



const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
    ({ children, header, open, onClose, position, width, ...props }, ref) => {
        const [display, setDisplay] = useState("none");
        useEffect(() => {
            let timeout: NodeJS.Timeout;
            if (open === true)
                setDisplay("block");
            else {
                timeout = setTimeout(() => {
                    setDisplay("none");
                    clearTimeout(timeout);
                }, 1000)
                return () => clearTimeout(timeout);
            }
        }, [open])

        return (
            // @ts-ignore
            <div id="overlayFull" style={{ "display": display, "--overlay-width": width || "65rem" }}
                className={`${classes.full} ${position === 'left' ? classes.leftModal : classes.rightModal}
                                                  ${open || classes.hidden}`}
                onClick={onClose}
            >
                <aside {...props} ref={ref}
                    onClick={(e) => { e.stopPropagation() }}
                    className={`${classes.overlay}
                    ${position || classes.right} ${position === 'right' && classes.right} ${position === 'left' && classes.left}`}
                >
                    <header className={classes.header}>
                        {typeof header === 'string' ?
                            overlayHeader(header)
                            :
                            header}
                        <button onClick={onClose}>
                            {/* @ts-ignore */}
                            <CloseIcon />
                        </button>
                    </header>
                    <div className={classes.body}>
                        {children}
                    </div>
                </aside>
            </div>
        );
    });


export default Overlay;
