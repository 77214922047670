import {HiOutlineHome} from 'react-icons/hi'
import {SiYourtraveldottv} from 'react-icons/si'
import {BiReceipt} from 'react-icons/bi'
import {FaHandHoldingHeart} from 'react-icons/fa'
import { FaUserFriends } from "react-icons/fa";
import { FaSortNumericDownAlt } from "react-icons/fa";
import classes from "../../layouts/sideBar/sidebar.module.css"

const sideBarAdmin = {
    list: [
        {
          key: "0",  
          title: "Dashboard",
          icon: <HiOutlineHome className={classes.icon}/>,
          route: "/admin/",
        },
        {
          key: "1",
          title: "Programs",
          icon: <SiYourtraveldottv className={classes.icon}/>,
          route: "/admin/programs",
        },
        {
          key: "2",
          title: "Users",
          icon: <FaUserFriends className={classes.icon}/>,
          route: "/admin/users",
        },
        {
          key: "3",
          title: "Counter",
          icon: <FaSortNumericDownAlt className={classes.icon}/>,
          route: "/admin/counter",
        },
      ],
}

export default sideBarAdmin;
