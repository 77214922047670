import React from "react";
import classes from "./footer.module.css";
import FooterData from "../../assets/data/FooterData";
import paymob from "../../assets/imgs/partners/paymob.png";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaTiktok } from "react-icons/fa";
import ContactUs from "../../pages/home/intro/contactus/ContactUs";

const Footer = () => {

  const [open, setOpen] = React.useState(false);

  function handleClick(e) {
    if (e.target.text === "Contact us") {
      e.preventDefault();
      setOpen(true);
    }
  }

  return (
    <footer className={classes.container}>
      <div className={classes.footer}>
        <div className={classes.data}>
          {FooterData.map((data, index) => {
            return (
              <div key={index}>
                <h3>{data.header}</h3>
                <ul>
                  {data.links.map((link, index) => {
                    return (
                      <li key={index}>
                        <a href={link.link} onClick={handleClick}>{link.title}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <div className={classes.newsLetter}>
          <div>
            <h3>Payment Partners</h3>
            <img src={paymob} alt="paymob" />
          </div>

          <div>
            <h3>Follow Us</h3>
            <div className={classes.socials}>
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/haveadream.org/">
                <FacebookOutlinedIcon sx={{ fontSize: "2.5rem" }} />
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/have.adream/">
                <InstagramIcon sx={{ fontSize: "2.5rem" }} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/have-a-dream-organization/about/"
              >
                <LinkedInIcon sx={{ fontSize: "2.5rem" }} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/user/HaveADreamOrg#:~:text=Have%20A%20Dream%20is%20an%20organization"
              >
                <YouTubeIcon sx={{ fontSize: "2.8rem" }} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.tiktok.com/@haveadreamorg"
              >
                <FaTiktok style={{ fontSize: "2rem" }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p>Copyright © 2023 . All right reserved</p>
      <ContactUs open={open} setOpen={setOpen} />


    </footer>
  );
};

export default Footer;
