export const FormModel = {
    summary: {
        type: "textArea",
        label: "Summary",
        row: 1,
        require: true
    },
    summary1: {
        type: "textArea",
        label: "Summary Header",
        row: 2,
        require: true
    },
    summary2: {
        type: "textArea",
        label: "Why you want to travel with us?",
        row: 3,
        require: true
    },
 }
