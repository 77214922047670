import React, { useState, useEffect, useRef } from "react";
import classes from "./counter.module.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Skeleton } from "antd";
const CounterCard = (props) => {
  let firstTime = true;
  function firstTimeFunc() {
    firstTime = false;

    return (
      <CountUp duration={4} className={classes.numCount} end={props.count} />
    );
  }
  return (
    <div className={classes.card}>
      <div>
        <img src={props.img} alt="404" className={classes.image} />
      </div>
      <div>
        {props.load ? (
          <div className={classes.skeleton}>
            <Skeleton.Input active size={"small"} />
          </div>   
        ) : (
          <VisibilitySensor partialVisibility offset={{ bottom: 7 }}>
            {({ isVisible }) => (
              <div>
                {isVisible && firstTime ? (
                  firstTimeFunc()
                ) : (
                  <p className={classes.numCount}>{props.count}</p>
                )}
              </div>
            )}
          </VisibilitySensor>
        )}
        <p className={classes.textCount}>{props.text}</p>
      </div>
    </div>
  );
};

export default CounterCard;
