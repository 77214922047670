import React, { useEffect } from 'react'
import classes from './profilebar.module.css'
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useSearchParams } from 'react-router-dom';


const ProfileBar = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams({ "tab": "Wishlist" });
    }
  }, []);

  return (
    <div className={classes.profileBar}>
      <ul>
        {/* <li className={searchParams.get("tab") === "My Feed" ? classes.selected : classes.Unselected} onClick={() => { setSearchParams({ tab: "My Feed" }) }}>My Feed</li> */}
        <li className={searchParams.get("tab") === "Wishlist" ? classes.selected : classes.Unselected} onClick={() => { setSearchParams({ tab: "Wishlist" }) }}><FavoriteIcon sx={{ fontSize: "1.7rem", color: "#F46444" }} /> Wishlist</li>
        <li className={searchParams.get("tab") === "Profile Info" ? classes.selected : classes.Unselected} onClick={() => { setSearchParams({ tab: "Profile Info" }) }}>Profile Info</li>
      </ul>
      <hr />
    </div>
  )
}

export default ProfileBar