import React, { useState ,useEffect} from "react";
import classes from "./auth.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import images from "../../assets/data/loginPhotos";
import { Link } from "react-router-dom";
import axios from "../../requests/axios"
import routes from "../../requests/routes"
import ErrorNotification from "../../generic components/error message/ErrorNotification";
import {useDispatch, useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
import GenericModal from "../../generic components/generic modal/GenericModal";
import {BiErrorCircle} from "react-icons/bi";
import {TfiEmail} from "react-icons/tfi";
import { NavLink } from "react-router-dom";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Loader from "../../layouts/loader/Loader";
import { jwtDecode } from "jwt-decode";
import { userActions } from "../../store/userSlice";

/**
 * Component that renders Signup page
 * 
 * @component
 * @example
 * return(<SignupPage />)
 */
const SignupPage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const [cont, setContinue] = useState(false);
  const [loader, setLoader] = useState(false);
  const [randImg, setrandImg] = useState(Math.floor(Math.random() * 3));
  const [myEmail, setMyEmail] = useState();


  const [errorMsg, setErrorMsg] = useState('');
  const [errorLink, setErrorLink] = useState('');
  const [errorLinkMsg, setErrorLinkMsg] = useState('');
  const[stateoftheconditionform,setstateoftheconditionform]=useState(false);
  const[agreeformstate,setagreeformstate]=useState(false);
  const[datainfo,setdatainfo]=useState();

  const location = useLocation();

    //To make sure user can't access signUp if he is already logged in 
    useEffect(() => {
      if(user.loggedIn){
        const redirectTo = location.state?.from || "/";  // Default to home if no redirection
        navigate(redirectTo);
      }
    }, []);


  const initialValues = {
    email: "",
    phoneNO: '',
    address: "",
    fullName: "",
    gender: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(3)
      .email("Invalid email address")
      .required(" Email field is required"),

    password: Yup.string().min(8).required("Field required"),
  });

  const openconditionform = () => {
    setstateoftheconditionform(true);
    
  };


 async function sendData(data){
  data.email=data.email.toLowerCase();
  setLoader(true);
  try{
    const request = await axios.post(routes.signUp, data)
    setagreeformstate(true);
    setLoader(false);
    
    
  } catch(err){
    setLoader(false);
    setErrorMsg("There is an account associated with the email.")
    setErrorLinkMsg("Log in")
    setErrorLink("/login")
  }
}  

const handleSubmit = (data) => {
  setdatainfo(data);
  setErrorMsg("")
  setErrorLinkMsg("")
  setErrorLink("")
  
};
const accepthandle=() =>{
  setstateoftheconditionform(false);
  sendData(datainfo);
}
const rejecthandle=()=>{
  setstateoftheconditionform(false);
  setErrorMsg('To continue you have to accept our Terms and conditions.')
}

const dispatch = useDispatch();

async function signInWithGoogle(data) {
  setLoader(true);
  try {
    const response = await axios.post(routes.googleLogin, data);
    let wishList = [];
    if (response.data.user.wishlist) {
      wishList = response.data.user.wishlist.map((item) => item._id);
    }
    dispatch(
      userActions.login({
        id: response.data.user._id,
        token: response.data.token,
        userType: response.data.user.userType,
        isAdmin: response.data.user.isAdmin,
        wishlist: wishList,
      })
    );

    sessionStorage.setItem("token", response.data.token);
    sessionStorage.setItem("id", response.data.user._id);
    const redirectTo = location.state?.from || "/";  // Default to home if no redirection
    navigate(redirectTo);
} catch (err) {
      setLoader(false);
      if (err.response.data.error === "Error: Password is incorrect") {
        setErrorMsg("Email or password is incorrect");
        // setShowForgetPass(true);
      } else if (
        err.response.data.error === "Error: email is not verified "
      ) {
        setErrorMsg("Email is not verified");
      } else {
        setErrorMsg("There is no account associated with the email.");
        setErrorLinkMsg("Create account");
        setErrorLink("/signup");
      }
    }
}

useEffect(() => {
  /*global google*/
  google?.accounts?.id?.initialize({
    client_id: "308652258795-mb31vae1b1qo7hko1ps1nsvc61bmrvbe.apps.googleusercontent.com",
    callback: handleCredentialResponse,
  });
  var width = "360";
  if (window.innerWidth < 580) {
    width = "255";
  }
  google?.accounts.id.renderButton(document.getElementById("g_id_onload"), {
    theme: "outline",
    size: "large",
    shape: "square",
    text: "continue_with",
    width: width,
  });

  //To make sure user can't access login if he is already logged in

  if (user.loggedIn) {
    const redirectTo = location.state?.from || "/";  // Default to home if no redirection
    navigate(redirectTo);
  }
}, []);

function handleCredentialResponse(response) {
  const token = response.credential;
  const decodedToken = jwtDecode(token);
  const data = {
    email: decodedToken.email,
    fullName: decodedToken.name,
    googleID: decodedToken.sub,
    image: decodedToken.picture,
  };
  if (decodedToken.email_verified) signInWithGoogle(data);
}


  return (
    <div>
      <div className={classes.main}>
            <NavLink className={classes.backButton} to="/login">
              <ArrowCircleLeftIcon className={classes.backArrowSignUp}/>
            </NavLink>
        <div className={classes.infoSignUp}>
          <div className={classes.form}>
            <div className={classes.header}>
              <h1>
                Sign Up
              </h1>
            </div>

            {errorMsg?
            <ErrorNotification mssg={errorMsg} linkmsg={errorLinkMsg} link={errorLink} signUp={true}/>:null}

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <Form>
                  {setMyEmail(values.email)}
                  <div className={classes.boxContainer}>
                      <label className={classes.label}> Email address </label>
                      <Field 
                        className={classes.field}
                        id="email"
                        name="email"
                        autoComplete="off"
                        disabled={cont}
                        data-testid="EmailFieldInput"
                      />
                    <ErrorMessage name="email" component="span" />
                  </div>
                    <div className={classes.boxContainer}>
                        <label className={classes.label}> Full Name </label>
                        <Field
                          className={classes.field}
                          name="fullName"
                          autoComplete="off"
                          data-testid="fullNamefield"
                        />
                      <ErrorMessage name="fullName" component="span" />
                    </div>
                    <div className={classes.boxContainer}>
                        <label className={classes.label}> Phone number </label>
                        <Field
                          className={classes.field}
                          name="phoneNO"
                          type="number"
                          autoComplete="off"
                          data-testid="phoneNOfield"
                        />
                      <ErrorMessage name="phoneNO" component="span" />
                    </div>
                    <div className={classes.boxContainer}>
                      <div className={classes.fieldContainer}>
                        <label className={classes.label}> Password</label>
                        <Field
                          className={classes.field}
                          name="password"
                          type="password"
                          autoComplete="off"
                          data-testid="Passwordfield"
                        />
                      </div>
                      <ErrorMessage name="password" component="span" />
                    </div>
                    <div
                      className={classes.btn}
                    >
                      <button type="submit" className={classes.button} data-testid="CreateBtn" onClick={openconditionform}>
                        Create account
                      </button>
                      {stateoftheconditionform &&(
                        <GenericModal 
                            header='Terms & conditions'
                            details='I accept Have A Dream Terms Of Services,Commuity guidelines and have read the privacy policy'
                            rejectbtn='Cancel'
                            confirmbtn='Agree'
                            icon={<BiErrorCircle className={classes.modalicon}/>}
                            accepthandle={accepthandle}
                            rejecthandle={rejecthandle}
                        />
                      )}
                      {agreeformstate &&(
                        <>
                           <GenericModal 
                                header='Verification Email has been sent to you'
                                icon={<TfiEmail className={classes.modalicon}/>}
                            />

                        </>
                      )}
                    </div>
                    {loader && <Loader color={"#F46444"}/>}
                </Form>
              )}
            </Formik>
            <Link to="/login">
              <p className={classes.changeLink}>Already have an account <b>Log in</b></p>
            </Link>
            <div id="g_id_onload"></div>
          </div>
        </div>
        <div
          className={classes.imgSignUp}
          style={{ backgroundImage: `url(${images[randImg]})` }}
        >
          <div className={classes.slogan}>
            <h2>Let’s fill those <br/> passport pages</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
