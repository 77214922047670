import React, { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import ProgramSidebar from "../../../../layouts/programSidebar/ProgramSidebar";
import classes from "./programDetails.module.css";

import FormikForm from "../../../../generic components/form/form";
import ReviewsList from "../../../../generic components/Reviews List/ReviewsList";
import axios from "../../../../requests/axios";
import myRoutes from "../../../../requests/routes";
import { programModel, transformAgeAndDuration } from "../programData";
import ProgramMain from "../programMain/ProgramMain";

const ProgramDetails = () => {
  const { id } = useParams();
  const [program, setProgram] = useState({});
  const [loading, setLoading] = useState(true);

  async function getProgram() {
    try {
      const response = await axios.get(myRoutes.getPrograms + `/${id}`);
      const prog = response?.data?.program;
      var startDate = new Date(prog?.startDate);
      var endDate = new Date(prog?.endDate);
      var applicationDeadline = new Date(prog?.applicationDeadline);
      setLoading(false);

      prog.startDate = startDate.toISOString().slice(0, 10);
      prog.endDate = endDate.toISOString().slice(0, 10);
      prog.applicationDeadline = applicationDeadline.toISOString().slice(0, 10);

      var slots = prog?.slots
      for (var j in slots) {
        for (var i in slots[j].dates) {
          var newStartDate = new Date(slots[j].dates[i].startDate);
          var newEndDate = new Date(slots[j].dates[i].endDate);
          var newDeadline = new Date(slots[j].dates[i].deadline);

          prog.slots[j].dates[i].startDate = newStartDate.toISOString().slice(0, 10);
          prog.slots[j].dates[i].endDate = newEndDate.toISOString().slice(0, 10);
          prog.slots[j].dates[i].deadline = newDeadline.toISOString().slice(0, 10);
        }
      }
      setProgram(prog);
    } catch (err) { }
  }

  useEffect(() => {
    getProgram();
  }, []);

  function areObjectsEqual(obj1, obj2) {
    if (obj1 === obj2) {
      return true;
    }
    // Check if both parameters are objects
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return false;
    }

    // Get the keys of the objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if both objects have the same number of keys
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Iterate over the keys
    for (const key of keys1) {
      // Check if the current key exists in both objects
      if (!obj2.hasOwnProperty(key)) {
        return false;
      }

      // Recursively compare nested objects
      if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
        if (!areObjectsEqual(obj1[key], obj2[key])) {
          return false;
        }
      } else {
        // Compare values
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }

    // If all keys and values match, return true
    return true;
  }
  function removeMatchingElements(values, program) {
    for (const key in values) {
      if (program.hasOwnProperty(key)) {
        if (
          typeof values[key] === "object" &&
          typeof program[key] === "object"
        ) {
          if (areObjectsEqual(values[key], program[key])) {
            delete values[key];
          }
        } else if (values[key] === program[key]) {
          delete values[key];
        }
      }
    }
  }
  const onSubmit = async (values, { setSubmitting }) => {
    removeMatchingElements(values, program);
    values = transformAgeAndDuration(values);
    await axios.patch(myRoutes.createProgram + `/${id}`, values);
    setSubmitting(false);
    // window.location.reload();
  };

  const basicFields = Object.fromEntries(
    Object.entries(programModel).filter(([_, v]) => !Array.isArray(v))
  );

  const arrayFields = Object.entries(programModel).filter(([_, v]) =>
    Array.isArray(v)
  );

  const routes = arrayFields.map(([k, _]) => {
    return {
      title: k,
      route: `/${k}`,
    };
  });
  routes.unshift({ title: "Basic Info", route: "/basic" });
  routes.unshift({ title: "Main", route: "/" });
  routes.push({ title: "Reviews", route: "/reviews" });

  return (
    <>
      <div className={classes.container}>
        {program && (
          <>
            <ProgramSidebar list={routes} />
            <div className={classes.content}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <div>
                      <ProgramMain program={{ ...program }} />
                    </div>
                  }
                />
                <Route
                  path="/basic"
                  element={
                    <FormikForm
                      model={basicFields}
                      initialValues={{ ...program }}
                      onSubmit={onSubmit}
                      submitMsg="Edit Program"
                      orderByRows={true}
                    />
                  }
                />
                {arrayFields.map(([k, v]) => (
                  <Route
                    key={k}
                    path={`/${k}`}
                    element={
                      <FormikForm
                        model={Object.fromEntries([[k, v]])}
                        initialValues={{ ...program }}
                        onSubmit={onSubmit}
                        submitMsg="Edit Program"
                        orderByRows={true}
                      />
                    }
                  />
                ))}
                <Route
                  path="/reviews"
                  element={
                    <ReviewsList
                      events={program.reviews ? program.reviews : [0, 0, 0]}
                      admin={true}
                      load={loading}
                    />
                  }
                />
              </Routes>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProgramDetails;
