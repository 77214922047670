import classes from './activities.module.css';
import ActivityCard from './activityCard/activityCard';

function Activities(props){
    const {activities} = props;
    

    return (
        <div className={classes.container}>
            {activities?.map((pin) => {
                return (
                    <ActivityCard pin={pin} />
                )
            })}
            
        </div>
    );
}

export default Activities;