import React from "react";
import { useState, useEffect } from "react";
import ReviewCard from "../review card/ReviewCard";
import classes from "./reviewsCarousel.module.css";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import notFound from "../../assets/imgs/notFound/programs.png";
import tripsTypes from "../../assets/data/tripsTypes";

const ReviewsCarousel = (props) => {
  // const [Eventcards, SetEventcards] = useState([0, 0, 0, 0]);
  const [loading, setLoading] = useState(true);

  const Eventcards = props.events;

  return (
    <div>
      <div className={classes.secheader}>
        {props.title && <h3>Trips</h3>}

      </div>
      <div className={classes.list}>
        {Eventcards?.length === 0 ? (
          <div className={classes.noevents}>
            <img alt="notFound" src={notFound} />
            <p>No programs in this category</p>
          </div>
        ) : (
          <AliceCarousel
            className={classes.carousel}
            mouseTracking items={Eventcards?.map((card) => (
              <ReviewCard
                id={card?._id}
                key={card?._id}
                progId={card?.program?._id}
                img={card?.user?.image}
                title={card?.user?.fullName}
                programName={card?.program?.name}
                startDate={card?.program?.startDate}
                endDate={card?.program?.endDate}
                location={card?.program?.location}
                load={props?.load}
                content={card?.text}
                rating={card?.rating}
                nationality={card?.user?.nationality}
                email={card?.user?.email}
                phone={card?.user?.phoneNO}
                isReview={true}
              />
            ))}
            responsive={{
              0: {
                items: 1,
                itemsFit: 'fill'
              },
              1100: {
                items: 2,
                itemsFit: 'fill'
              },
              1024: {
                items: 3,
                itemsFit: 'fill',
              }
            }}
            renderDotsItem={(e) => { return <div className={e.isActive ? classes.carouselIndexBtnActive : classes.carouselIndexBtn}></div> }}
            disableButtonsControls={true}
          />
        )}
      </div>
      {!loading && Eventcards.length != 0 && (
        <div className={classes.moreBtn}>
          <button type="button">See more</button>
        </div>
      )}
    </div>
  );
};

export default ReviewsCarousel;
