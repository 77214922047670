import React, { useState, useEffect } from "react";
import classes from "./reviews.module.css";
import illustration from "../../assets/imgs/reviews/review.png";
import circle from "../../assets/imgs/reviews/circle.svg";
import EmptyReviews from "../../assets/imgs/reviews/emptyPage.png";

//import ReviewCard from "./reviewCard/ReviewCard";

import axios from "../../requests/axios";
import routes from "../../requests/routes";
import GlobeLoader from "../../layouts/globeLoader/GlobeLoader";

import ReviewCard from "../../generic components/review card/ReviewCard";

const Reviews = (props) => {

  const [reviewsData, setreviewsData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getReviews() {
    try {
      const response = await axios.get(routes.getReviews);
      setreviewsData(response.data.reviews);
      setLoading(false);

    } catch (err) {
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getReviews();
  }, []);
  const ReviewsTempData = [
    {
      _id: "1",
      user: {
        image: "https://res.cloudinary.com/dhyrsg55i/image/upload/v1709541483/nc3ciqmsql7kfwerjpol.jpg",
        fullName: "Zyad Elhalafawy",
      },
      program: {
        name: "Fly to Connect",
        location: "Kenya",
        startDate: "2024-04-01T00:00:00.000+00:00",
        endDate: "2024-04-08T00:00:00.000+00:00",
        _id: "65e58826618268452fdddc6b",
      },
      text:
        "I was so glad to be a member of the volunteer team in Kenya. I received assistance from the team all the time when I was in Kenya. They were very kind, helpful, and on time responded.",
      rating: 5,
    },
    {
      _id: "2",
      user: {
        image: "https://res.cloudinary.com/dhyrsg55i/image/upload/v1710166661/yw3a7imwth62aonifl4p.jpg",
        fullName: "Abdelrahman Masoud",
      },
      program: {
        name: "Fly to Connect",
        location: "Zanzibar, Tanzania",
        startDate: "2024-04-15T00:00:00.000+00:00",
        endDate: "2025-12-21T00:00:00.000+00:00",
        _id: "65eef478671b961860895982",
      },
      text:
        "Life changing. It was good to know people as they were very kind. Teaching also makes you fulfilled as you see the effect on the people.",
      rating: 5,
    },
    {
      _id: "3",
      user: {
        image: "https://res.cloudinary.com/dhyrsg55i/image/upload/v1711627142/ld1yubl6b5ylnpnwrvg0.jpg",
        fullName: "Rawia Almadni",
      },
      program: {
        name: "Fly to Connect",
        location: "Thailand",
        startDate: "2023-04-15T00:00:00.000+00:00",
        endDate: "2023-12-21T00:00:00.000+00:00",
        _id: "65ef1cbc5bb84b956d5be03d",
      },
      text:
        "I've always wanted to travel, but not as a tourist and this experience definitely satisfied my curiosity and fueled my long-standing passion for Asian cultures. I learned a lot about Thailand and its hospitable, generous people, immersed myself in the breathtaking natural landscapes, visited some amazing landmarks, learned how to make Pad Thai and even took a Muay Thai class.",
      rating: 5,
      },
  ];

  return (
    <div className={classes.container}>
      {
        loading ?
          <GlobeLoader /> :
          ReviewsTempData.length === 0 ?
            <div className={classes.emptyPage}>
              <h2>No Reviews Yet</h2>
              <img src={EmptyReviews} alt="illustration" />
            </div> :
            <div className={classes.container}>
              <div className={classes.header}>
                <h1 className={classes.title}>what past travellers say about their adventures</h1>
                <div className={classes.imgs}>
                  <img className={classes.circle} src={circle} alt="circle" />
                  <img className={classes.illustration} src={illustration} alt="illustration" />
                </div>
              </div>
              <div className={classes.reviews}>
                {ReviewsTempData?.map((review, index) => {
                    return (
                      review.program ?
                        <ReviewCard 
                        id={review._id}
                        key={review._id}
                        progId={props.load?"":review.program._id}
                        img={props.load?"":review.user.image}
                        title={props.load?"":review.user.fullName}
                        programName={props.load?"":review?.program?.name}
                        startDate={props.load?"":review.program.startDate}
                        endDate={props.load?"":review.program.endDate}
                        location={props.load?"":review.program.location}
                        load={props.load}
                        content={review.text}
                        rating={review.rating}
                        nationality={props.load?"":review.user.nationality}
                        email={props.load?"":review.user.email}
                        phone={props.load?"":review.user.phoneNO}
                        isReview={true}  
                        />
                        : <></>
                    )
                })}
              </div>
            </div>
      }


    </div>
  );
};

export default Reviews;
