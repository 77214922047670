import React, { useState, useEffect } from "react";
import Overlay from "../../../generic components/overlay/Overlay.tsx";
import FormikForm from "../../../generic components/form/form";
import { getInitialValues } from "../../../generic components/form/formUtils";
import { programModel, transformAgeAndDuration } from "./programData";
import ProgramsList from "../programs/programList/ProgramList";
import "../../../index.css";
import routes from "../../../requests/routes";
import axios from "../../../requests/axios";

const AdminPrograms = () => {
  const [addProgram, setAddProgram] = useState(false);

  const initialValues = getInitialValues(programModel);
  const onSubmit = async (values, { setSubmitting, setValues }) => {
    console.log(values);
    values = transformAgeAndDuration(values);
    console.log(values);
    const program = await axios.post(routes.createProgram, values);
    setSubmitting(false);
    setValues(initialValues);
    window.location.reload();
  };


  return (
    <div style={{ maxWidth: "70vw", margin: 'auto' }}>
      <h1 className="adminTitle">Programs</h1>
      <div className="btn">
        <button onClick={() => setAddProgram(true)} className="button">
          Add Program
        </button>
      </div>
      <ProgramsList />
      <Overlay
        open={addProgram}
        onClose={() => setAddProgram(false)}
        header="Add Program"
        width="75rem"
      >
        <FormikForm
          model={programModel}
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitMsg="Create Program"
          orderByRows={true}
        />
      </Overlay>
    </div>
  );
};

export default AdminPrograms;
