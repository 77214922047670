import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Rating from '@mui/material/Rating';
import React, { useState } from "react";
import Location from "../../assets/imgs/contactUs/Location.png";
import axios from "../../requests/axios";
import routes from "../../requests/routes";
import classes from "./ReviewPopUp.module.css";



function ReviewPopUp(props) {
    const [value, setValue] = useState(5);
    const [opinion, setOpinion] = useState("");
    const [open1, setOpen1] = useState(false);

    function handleModalClose1() {
        setOpen1(false);
    }

    function handleModalClose() {
        props.setOpen(false);
    }
    async function submitReview() {
        try {
            await axios.post(routes.postReview, { program: props.id, rating: value, text: opinion });
            handleModalClose();
            setOpen1(true);
        } catch (err) {
        }
    }
    function handleSubmit(){
        submitReview();
    }


    return (
        <>
        <Modal open={props.open} onClose={handleModalClose}>
            <Box className={classes.box}>
                <div className={classes.container}>
                    <div className={classes.close}>
                        <button onClick={handleModalClose}>
                        x
                        </button>
                    </div>
                    <h1 className={classes.title}>{props.title}</h1>
                    <div className={classes.location}>
                        <img src={Location} alt="location"/>
                        <p>{props.location}</p>
                    </div>
                    <div className={classes.reviewContent}>
                        <div className={classes.reviewSection}>
                            <p className={classes.ratingText}>What's Your Rating?</p>
                            <Rating
                                className={classes.rating}
                                size="large"
                                name="simple-controlled"
                                value={value}
                                onChange={(event, newValue) => {
                                setValue(newValue);
                                }}
                                sx={{
                                "& .MuiRating-iconFilled": {
                                color: "#F46444"
                                },
                                "& .MuiRating-iconHover": {
                                color: "#F46444"
                                }
                            }}
                            />
                            <p className={classes.shareOpinion}>Share your opinion about the program</p>
                            <textarea className={classes.opinion} placeholder="Write your opinion here..." onChange={(e)=>setOpinion(e.target.value)}></textarea>
                        </div>
                        <div className={classes.reviewImgButton}>
                            <div className={classes.imgArea}>
                                <img src={props.img} alt="program" className={classes.programImg}/>
                            </div>
                            <button className={classes.submitBtn} onClick={handleSubmit}>Submit Review</button>
                        </div>
                    </div>

                </div>
                
            </Box>
        </Modal>
        <Modal open={open1} onClose={handleModalClose1}>
            <Box className={classes.box1}>
                <div className={classes.container1}>
                    <p className={classes.title}>Thank you for your review!</p>
                </div>
            </Box>
        </Modal>
        </>
    );

}

export default ReviewPopUp;