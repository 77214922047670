import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import MyToaster from "../../../generic components/toaster/MyToaster.js";
import axios from "../../../requests/axios";
import routes from "../../../requests/routes";
import classes from "./subscribe.module.css";

const Subscribe = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter your first name"),
    email: Yup.string()
      .min(3, "Please enter a valid email address")
      .email("Please enter a valid email address")
      .required("Please enter a valid email address"),

    // check for a valid egyptian mobile number

    phoneNo: Yup.string()
      .min(11, "enter a valid phone number")
      .matches(/^(01)[0-9]{9}$/, "enter a valid phone number")
      .required("enter your phone number"),
  });
  // const [loader,setLoader] = useState(false);

  async function handleSubmit(data, {resetForm}) {
    
    async function sendRequest(){
    try {
      await axios.post(routes.newsLetter, data);
      resetForm(initialValues);
    } catch (err) {
      console.log(err);
    }
  }
    toast.promise(sendRequest(), {
      loading: "Loading",
      success: "Subscribed successfully",
      error: "Error occurred",
    });

  }

  return (
    <div className={classes.container}>
      <div className={classes.leftSection}>
        <div className={classes.leftSectionContent}>
          <div className={classes.titles}>
            <h1 className={classes.header}>Subscribe To</h1>
            <h1 className={classes.header}>Our Newsletter</h1>
          </div>
          <p className={classes.text}>
            Stay up to date with our latest news about programs, destinations,
            and more
          </p>
        </div>
      </div>
      <div className={classes.rightSection}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <div className={classes.boxContainer}>
                <div className={classes.nameFields}>
                  <div className={classes.name}>
                    <label className={classes.label}>First Name <span>*</span></label>
                    <Field
                      className={classes.field}
                      name="firstName"
                      type="text"
                      autoComplete="off"
                    />
                    <ErrorMessage name="firstName" component="span" />
                  </div>
                  <div className={classes.name}>
                    <label className={classes.label}>Last Name</label>
                    <Field
                      className={classes.field}
                      name="lastName"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <label className={classes.label}>Email <span>*</span></label>
                <Field
                  className={classes.field}
                  name="email"
                  type="email"
                  autoComplete="off"
                />
                <ErrorMessage name="email" component="span" />
                <label className={classes.label}>Phone Number <span>*</span></label>
                <Field
                  className={classes.field}
                  name="phoneNo"
                  type="text"
                  autoComplete="off"
                />
                <ErrorMessage name="phoneNo" component="span" />
              </div>

              <div className={classes.btn}>
                <button type="submit" className={classes.button}>
                  Subscribe
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Subscribe;
