import classes from './itineraryCard.module.css';
import { useEffect, useRef } from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';


function ItineraryCard(props) {
    const cardRef = useRef(null);
    const progressBarRef = useRef(null);
    const {pin} = props;

    useEffect(() => {
        if (cardRef.current.scrollHeight <= cardRef.current.clientHeight) {
            progressBarRef.current.style.display = 'none';
            return;
        }
        cardRef.current.addEventListener('scroll', () => {
            let scrollTop = cardRef.current.scrollTop;
            let docHeight = cardRef.current.scrollHeight;
            let winHeight = cardRef.current.clientHeight;
            let scrollPercent = scrollTop / (docHeight - winHeight);
            let degrees = scrollPercent * 360;
            progressBarRef.current.style.background =
                `conic-gradient(var(--orange) ${degrees}deg, rgb(from var(--primary-text) r g b/.1) ${degrees}deg)`;
        });
    }, [cardRef, progressBarRef]);

    return (
        <div class={classes.card}>
            <h2 class={classes.cardTitle}>Days {pin?.start} {pin?.end?"-"+pin?.end:null}</h2>
            <div ref={cardRef} class={classes.details}>
                {pin?.locations?.map((location, index) => {
                    return (
                        <div key={index} class={classes.location}>
                            <h3 class={classes.locationTitle}>{location.city}</h3>
                            <div class={classes.locationList}>
                                {location.places.map((place, index) => {
                                    return (
                                        <div key={index} class={classes.locationListItem}>{place}</div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                }
                )}
            </div>
            <div ref={progressBarRef} class={classes.progressBar} onClick={() => { cardRef.current.scrollTop = cardRef.current.scrollHeight }}>
                <div class={classes.circle}>
                    <KeyboardArrowDownRoundedIcon />
                </div>
            </div>
        </div>
    );
}

export default ItineraryCard;