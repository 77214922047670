import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { TfiEmail } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import images from "../../assets/data/loginPhotos";
import ErrorNotification from "../../generic components/error message/ErrorNotification";
import GenericModal from "../../generic components/generic modal/GenericModal";
import Loader from "../../layouts/loader/Loader";
import axios from "../../requests/axios";
import routes from "../../requests/routes";
import { userActions } from "../../store/userSlice";
import classes from "./auth.module.css";


const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const location = useLocation();

  const [randImg, setrandImg] = useState(Math.floor(Math.random() * 3));
  const [email, setEmail] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [errorLink, setErrorLink] = useState("");
  const [errorLinkMsg, setErrorLinkMsg] = useState("");

  const [showForgetPass, setShowForgetPass] = useState(false);
  const [forgetPasswordModal, setForgetPasswordModal] = useState(false);


  function handleCredentialResponse(response) {
    const token = response.credential;
    const decodedToken = jwtDecode(token);
    const data = {
      email: decodedToken.email,
      fullName: decodedToken.name,
      googleID: decodedToken.sub,
      image: decodedToken.picture,
    };
    if (decodedToken.email_verified) signInWithGoogle(data);
  }

  //To make sure user can't access login if he is already logged in
  useEffect(() => {
    /*global google*/
    google?.accounts?.id?.initialize({
      client_id: "308652258795-mb31vae1b1qo7hko1ps1nsvc61bmrvbe.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    var width = "360";
    if (window.innerWidth < 580) {
      width = "255";
    }
    google?.accounts.id.renderButton(document.getElementById("g_id_onload"), {
      theme: "outline",
      size: "large",
      shape: "square",
      text: "continue_with",
      width: width,
    });

    //To make sure user can't access login if he is already logged in

    if (user.loggedIn) {
      // navigate("/");
    }
  }, []);





  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(3)
      .email("Please enter a valid email address")
      .required("Please enter a valid email address"),
    password: Yup.string().required("Password is required"),
  });
  const [loader, setLoader] = useState(false);

  const handleSubmit = (data, { setErrors }) => {
    setErrorMsg("");
    setErrorLinkMsg("");
    setErrorLink("");
    setEmail(data.email.toLowerCase());

    async function sendData() {
      setLoader(true);
      try {
        data.email = data.email.toLowerCase();
        const response = await axios.post(routes.logIn, data);
        setLoader(false);
        let wishList = [];
        if (response.data.user.wishlist) {
          wishList = response.data.user.wishlist.map((item) => item._id);
        }
        dispatch(
          userActions.login({
            id: response.data.user._id,
            token: response.data.token,
            userType: response.data.user.userType,
            isAdmin: response.data.user.isAdmin,
            wishlist: wishList,
          })
        );
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("id", response.data.user._id);
        const redirectTo = location.state?.from || "/programs";  // Default to home if no redirection
        navigate(redirectTo);
      } catch (err) {
        setLoader(false);
        // console.log("X" + err.response.data.error + "x");
        if (err.response.data.error === "Error: Password is incorrect") {
          setErrorMsg("Email or password is incorrect");
          setShowForgetPass(true);
        } else if (
          err.response.data.error === "Error: email is not verified "
        ) {
          setErrorMsg("Email is not verified");
        } else {
          setErrorMsg("There is no account associated with the email.");
          setErrors({
            email: "There is no account associated with the email.",
          });
          setErrorLinkMsg("Create account");
          setErrorLink("/signup");
        }
      }
    }
    sendData();
  };


  async function signInWithGoogle(data) {
    setLoader(true);
    try {
      const response = await axios.post(routes.googleLogin, data);
      let wishList = [];
      if (response.data.user.wishlist) {
        wishList = response.data.user.wishlist.map((item) => item._id);
      }
      dispatch(
        userActions.login({
          id: response.data.user._id,
          token: response.data.token,
          userType: response.data.user.userType,
          isAdmin: response.data.user.isAdmin,
          wishlist: wishList,
        })
      );

      sessionStorage.setItem("token", response.data.token);
      sessionStorage.setItem("id", response.data.user._id);
      const redirectTo = location.state?.from || "/";  // Default to home if no redirection
      navigate(redirectTo);
    } catch (err) {
      setLoader(false);
      if (err.response.data.error === "Error: Password is incorrect") {
        setErrorMsg("Email or password is incorrect");
        setShowForgetPass(true);
      } else if (
        err.response.data.error === "Error: email is not verified "
      ) {
        setErrorMsg("Email is not verified");
      } else {
        setErrorMsg("There is no account associated with the email.");
        setErrorLinkMsg("Create account");
        setErrorLink("/signup");
      }
    }
  }



  const handleForgetPassword = () => {
    setForgetPasswordModal(false);

    async function sendData() {
      try {
        await axios.patch(routes.forgotPassword, {
          email: email,
        });
        setForgetPasswordModal(true);
      } catch (err) {
        console.log(err);
      }
    }

    sendData();
  };

  return (
    <div data-testid="LoginComponent">
      <div className={classes.main}>
        <NavLink to="/">
          <ArrowCircleLeftIcon className={classes.backArrow} />
        </NavLink>
        <div className={classes.shape1}>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#C8E3E2"
              d="M67,-23.1C73.4,-2.1,55.9,25.3,32.3,41.8C8.7,58.3,-20.8,63.9,-38.1,51.6C-55.3,39.3,-60.1,9.1,-51.7,-14.7C-43.3,-38.5,-21.6,-55.9,4.3,-57.3C30.3,-58.7,60.6,-44.1,67,-23.1Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
        <div className={classes.shape2}>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#C8E3E2"
              d="M67,-23.1C73.4,-2.1,55.9,25.3,32.3,41.8C8.7,58.3,-20.8,63.9,-38.1,51.6C-55.3,39.3,-60.1,9.1,-51.7,-14.7C-43.3,-38.5,-21.6,-55.9,4.3,-57.3C30.3,-58.7,60.6,-44.1,67,-23.1Z"
              transform="translate(100 100)"
            />
          </svg>
        </div>
        <div
          className={classes.imageLogin}
          style={{ backgroundImage: `url(${images[randImg]})` }}
        >
          <div className={classes.slogan}>
            <h2>
              Showing you the world, one country <br /> at a time
            </h2>
          </div>
        </div>
        <div className={classes.infoLogin}>
          <div className={classes.form}>
            <div className={classes.header}>
              <h1>Sign in</h1>
            </div>

            {errorMsg ? (
              <ErrorNotification
                mssg={errorMsg}
                linkmsg={errorLinkMsg}
                link={errorLink}
              />
            ) : null}

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <Form>
                  {setEmail(values.email)}
                  <div className={classes.boxContainer}>
                    <label className={classes.label}> Email address</label>
                    <Field
                      className={classes.field}
                      name="email"
                      autoComplete="off"
                      data-testid="LoginFormEmailInput"
                    />
                    <ErrorMessage
                      name="email"
                      component="span"
                      data-testid="emailError"
                    />
                  </div>
                  <div className={classes.boxContainer}>
                    <label className={classes.label}> Password</label>
                    <Field
                      className={classes.field}
                      name="password"
                      type="password"
                      autoComplete="off"
                      data-testid="LoginFormPasswordInput"
                    />
                    <ErrorMessage name="password" component="span" />
                  </div>
                  {showForgetPass && (
                    <p
                      className={classes.screenLink}
                      onClick={handleForgetPassword}
                    >
                      Forgot password?
                    </p>
                  )}
                  <div className={classes.btn}>
                    <button
                      type="submit"
                      className={classes.button}
                      data-testid="LoginFormSubmitButton"
                    >
                      Sign in
                    </button>
                  </div>
                  <Link to="/signup">
                    <p className={classes.changeLink}>
                      {" "}
                      Do not have an account? <b>Sign up</b>{" "}
                    </p>
                  </Link>
                  <div id="g_id_onload"></div>
                  {loader && <Loader color={"#F46444"} />}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {forgetPasswordModal && (
        <GenericModal
          header="Check your email to update your password"
          details={"We sent a link to " + `${email}`}
          icon={<TfiEmail className={classes.modalicon} />}
        />
      )}
    </div>
  );
};

export default LoginPage;
