import React, { useState } from 'react';
import classes from './anchor.module.css';

import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

export default function Anchor(props) {
  const [show, setShow] = useState(false);

  window.onscroll = () => {
    const topOffset = props?.topOffset || 100
    if (window.scrollY > topOffset) {
      setShow(true);
    } else {
      setShow(false);
    }
  }

  const handleClick = () => {
    const top = props?.top || 0
    window.scroll(0, top);
  }

  return (
    <div className={`${classes.container} ${show ? classes.show : ''}`} >
      <button className={classes.button} onClick={handleClick}>
        <KeyboardArrowUpRoundedIcon className={classes.icon} />
      </button>
    </div>
  )
}
